import s from './SubMenu.scss';
import { NavigationItem, TextKey } from '../../data/types';
import { Translator } from '../../modules/translate';
import { SubMenuItem } from '../SubMenuItem/SubMenuItem';
import { SideInfoBlock } from '../SideInfoBlock/SideInfoBlock';
import { DataHook } from '../../modules/dataHooks';

export interface SubMenuProps {
  id: string;
  items: NavigationItem[];
  t: Translator;
  blockList?: NavigationItem[];
  title?: TextKey;
  blockButton?: NavigationItem;
  isBigDropdown?: boolean;
}

export const SubMenu = ({ items, t, blockList, title, blockButton, isBigDropdown, id }: SubMenuProps) => {
  const bigDropdownClass = isBigDropdown ? s.bigDropdownWrapper : '';
  const rowNumbers = items.length >= 4 ? 4 : items.length;

  return `
    <div class="${s.container} ${bigDropdownClass} sub-menu-items-${rowNumbers}" data-hook="${
    DataHook.SubMenu
  }" role="group" aria-labelledby="${id}">
      <div class="${s.background}"></div>
      <ul class="${s.subMenu}">
        ${items.map(item => SubMenuItem({ item, t })).join('')}
      </ul>
      ${blockList && title ? SideInfoBlock({ title, blockList, blockButton, t }) : ''}
      ${!blockButton ? `<div class="${s.mobileSeparator}"></div>` : ''}
    </div>
  `;
};
