import { BaseElementController } from '../../modules/BaseElementController';
import { DataHook } from '../../modules/dataHooks';

interface MenuToggleControllerCallbacks {
  onOpen?: Function;
  onHide?: Function;
}

export class MenuToggleController extends BaseElementController {
  private readonly element: HTMLButtonElement;
  constructor() {
    super();
    this.element = this.$<HTMLButtonElement>(this.getSelectorByDataHook(DataHook.HamburgerButton))!;
  }
  init({ onOpen, onHide }: MenuToggleControllerCallbacks = {}) {
    this.element.addEventListener('click', () => {
      if (!this.isOpened()) {
        this.openMenu();
        onOpen && onOpen();
      } else {
        this.closeMenu();
        onHide && onHide();
      }
    });
  }

  closeMenu() {
    this.element.dataset.expanded = 'false';
    this.element.setAttribute('aria-expanded', 'false');
  }

  openMenu() {
    this.element.dataset.expanded = 'true';
    this.element.setAttribute('aria-expanded', 'true');
  }

  isOpened() {
    return this.element.dataset.expanded === 'true';
  }
}
