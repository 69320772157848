import s from './MenuButton.scss';

export class MenuButtonController {
  private readonly wrapper: HTMLDivElement;
  private readonly button: HTMLButtonElement | HTMLAnchorElement;
  constructor(el: HTMLDivElement) {
    this.wrapper = el;
    this.button = el.firstElementChild as HTMLButtonElement | HTMLAnchorElement;
  }

  activate() {
    if (!this.isActive()) {
      this.wrapper.classList.add(s.active);
      this.button.setAttribute('aria-expanded', 'true');
    }
  }

  inactivate() {
    if (this.isActive()) {
      this.wrapper.classList.remove(s.active);
      this.button.setAttribute('aria-expanded', 'false');
    }
  }

  isActive() {
    return this.wrapper.classList.contains(s.active);
  }

  getButtonWrapper() {
    return this.wrapper;
  }

  getButtonElement() {
    return this.button;
  }

  getName() {
    return this.button.getAttribute('data-bi-name') || undefined;
  }

  getLink() {
    return this.button.getAttribute('href') || undefined;
  }
}
