import s from './Separator.scss';

type SeparatorProps = {
  className?: string;
  tag?: string;
};
export const Separator = ({ className, tag = 'div' }: SeparatorProps = {}) => {
  const finalClassName = [s.separator, className].filter(Boolean).join(' ');
  return `<${tag} class="${finalClassName}" aria-hidden="true"></${tag}>`;
};
