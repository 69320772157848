import s from './UserMenu.scss';
import { UserProfileData } from '../../modules/DataFetcher';
import { Translator } from '../../modules/translate';
import { MenuItemWrapper } from '../MenuItemWrapper/MenuItemWrapper';

export interface UserMenuProps {
  userProfileData: UserProfileData;
  userImage: string;
  t: Translator;
}

export const UserMenuMobile = ({ t, userImage, userProfileData: { userName } }: UserMenuProps) => {
  return `
    ${MenuItemWrapper({
      className: s.mobileContainer,
    })(`
    <div class="${s.buttonWrapper}">
     <img class="${s.image}" src="${userImage}" alt="${t('profile_image')}" />
      <div class="${s.mobileWrapper}">
        <span class="${s.userNameText}" title="${userName}">
          ${userName}
        </span>
        <a class="${s.signOut}" href="https://www.wix.com/signout" data-bi-row="second" data-bi-name="sign_out">
              ${t('menu_sign_out')}
        </a>
      </div>
    </div>
    <div class="${s.separator}"></div>
`)}`;
};
