import { UserMenuDesktop, UserMenuDesktopProps } from './UserMenuDesktop';
import { BaseElementController } from '../../modules/BaseElementController';
import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';
import s from './UserMenu.scss';
import { UserMenuMobile } from './UserMenuMobile';
import { Config } from '../../modules/Config';

interface UserMenuControllerProps extends UserMenuDesktopProps {
  biLogger: BiLogger;
  config: Config;
}

interface UserMenuCallbacks {
  onOpen?: Function;
  onLanguageButtonClick?: Function;
}

export class UserMenuController extends BaseElementController {
  private readonly biLogger: BiLogger;
  private readonly config: Config;
  private readonly elements: {
    desktop: {
      container: HTMLLIElement;
      toggleWrapper: HTMLDivElement;
      toggleButton: HTMLButtonElement;
      languageButton: HTMLButtonElement;
      signOutLink: HTMLAnchorElement;
      links: HTMLAnchorElement[];
    };
    mobile: {
      container: HTMLLIElement;
      signOutLink: HTMLAnchorElement;
    };
  };

  constructor({ biLogger, ...props }: UserMenuControllerProps) {
    super();
    this.biLogger = biLogger;
    this.config = props.config;

    const desktopContainer = this.createElementFromHTML<HTMLLIElement>(UserMenuDesktop(props));
    const mobileContainer = this.createElementFromHTML<HTMLLIElement>(UserMenuMobile(props));

    this.elements = {
      desktop: {
        container: desktopContainer,
        toggleWrapper: desktopContainer.firstElementChild! as HTMLDivElement,
        toggleButton: this.$<HTMLButtonElement>(`.${s.button}`, desktopContainer)!,
        languageButton: this.$<HTMLButtonElement>(`.${s.languageButton}`, desktopContainer)!,
        links: this.$$<HTMLAnchorElement>(`a.${s.link}`, desktopContainer),
        signOutLink: this.$<HTMLAnchorElement>(`[data-bi-name=sign_out]`, desktopContainer)!,
      },
      mobile: {
        container: mobileContainer,
        signOutLink: this.$<HTMLAnchorElement>(`[data-bi-name=sign_out]`, mobileContainer)!,
      },
    };
  }

  renderUserMenu(callbacks: UserMenuCallbacks) {
    this.initToggleButton(callbacks);

    this.initLanguageButton(callbacks);

    this.initDropdown();
    this.updateSignOutLink();
    return {
      desktop: this.elements.desktop.container,
      mobile: this.elements.mobile.container,
    };
  }

  public expandMenu() {
    this.elements.desktop.toggleButton.setAttribute('aria-expanded', 'true');
    this.elements.desktop.container.setAttribute('data-expanded', 'true');
    this.biLogger.openTab({ tab: this.getTabName() });
  }

  public collapseMenu() {
    if (this.isExpanded()) {
      this.elements.desktop.toggleButton.setAttribute('aria-expanded', 'false');
      this.elements.desktop.container.setAttribute('data-expanded', 'false');
      this.biLogger.closeTab({ tab: this.getTabName() });
    }
  }

  public isExpanded() {
    return this.elements.desktop.container.dataset.expanded === 'true';
  }

  getTabName() {
    return this.getButtonName(this.elements.desktop.toggleButton);
  }

  getButtonName(el: HTMLElement) {
    return el.getAttribute('data-bi-name') || undefined;
  }

  initToggleButton({ onOpen }: UserMenuCallbacks) {
    const { toggleWrapper } = this.elements.desktop;
    toggleWrapper.addEventListener('click', () => {
      if (this.isDesktop()) {
        if (!this.isExpanded()) {
          this.expandMenu();
          onOpen && onOpen();
        } else {
          this.collapseMenu();
        }
      }
      this.biLogger.clickButton({ button_name: this.getTabName() });
    });

    fireBiEventsOnHover({ element: toggleWrapper, name: this.getTabName(), logger: this.biLogger });
  }

  initDropdown() {
    this.elements.desktop.links.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        const referencedWindow = link.target === '_blank' ? window.open() : window;
        this.biLogger
          .clickButton({ button_name: this.getButtonName(link), to: link.href, tab: this.getTabName() })
          .then(() => {
            referencedWindow && referencedWindow.location.assign(link.href);
          });
      });

      fireBiEventsOnHover({
        element: link,
        name: this.getButtonName(link),
        logger: this.biLogger,
        href: link.href,
        tab: this.getTabName(),
      });
    });
  }

  initLanguageButton({ onLanguageButtonClick }: UserMenuCallbacks) {
    const { languageButton } = this.elements.desktop;
    languageButton.addEventListener('click', () => {
      this.biLogger.clickButton({ button_name: this.getButtonName(languageButton), tab: this.getTabName() });
      this.biLogger.openTab({ tab: this.getButtonName(languageButton) });
      onLanguageButtonClick && onLanguageButtonClick();
    });

    fireBiEventsOnHover({
      element: languageButton,
      name: this.getButtonName(languageButton),
      logger: this.biLogger,
      tab: this.getTabName(),
    });
  }

  getToggleButtonElement() {
    return this.elements.desktop.toggleButton;
  }

  getLanguageButtonElement() {
    return this.elements.desktop.languageButton;
  }

  private updateSignOutLink() {
    const postSignOut = this.config.getPostSignOut();
    if (postSignOut) {
      const queryParams = new URLSearchParams({ redirectTo: postSignOut }).toString();
      const { desktop, mobile } = this.elements;
      desktop.signOutLink.href = `${desktop.signOutLink}?${queryParams}`;
      mobile.signOutLink.href = `${mobile.signOutLink}?${queryParams}`;
    }
  }
}
