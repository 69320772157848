import { createTranslator, Messages, Translator } from './translate';
import { Config } from './Config';
import { StructureBuilder } from '../data/StructureBuilder';
import { getAllLanguages, SupportedLanguage } from '../data/languages';
import { HeaderController } from '../components/Header/HeaderController';
import { DataFetcher } from './DataFetcher';
import { BiLogger } from './BiLogger';
import { Experiments } from '../experiments';
import { MOBILE_MAX_WIDTH, MORE_DROPDOWN_MAX_WIDTH } from './breakpoints';

const BREAKPOINTS = [MOBILE_MAX_WIDTH, MORE_DROPDOWN_MAX_WIDTH];

interface HeaderParams {
  messages: Messages;
  config: Config;
  dataFetcher: DataFetcher;
  experiments: Experiments;
  biLogger: BiLogger;
}

export class HeaderFooterController {
  private currentClosestBreakpoint: number;
  private messages?: Messages;
  private config?: Config;
  private dataFetcher?: DataFetcher;
  private experiments?: Experiments;
  private biLogger?: BiLogger;

  constructor() {
    this.currentClosestBreakpoint = this.findClosestBreakpoint();
    window.addEventListener('resize', this.handleResize);
  }

  // closest bigger breakpoint
  private findClosestBreakpoint = () => [...BREAKPOINTS, +Infinity].find(value => value >= window.innerWidth)!;

  private renderHeaderComponent() {
    const { config, messages, dataFetcher, experiments, biLogger } = this;
    if (config && messages && dataFetcher && experiments && biLogger) {
      const t = createTranslator(messages);
      const builder = new StructureBuilder(config, { experiments });
      const headerStructure = builder.getHeaderStructure(this._extractRedirectToLinks());

      const headerController = new HeaderController({
        headerStructure,
        t,
        config,
        dataFetcher,
        biLogger,
        experiments,
      });
      headerController.init();
    }
  }

  renderHeader({ config, messages, dataFetcher, experiments, biLogger }: HeaderParams) {
    this.config = config;
    this.messages = messages;
    this.dataFetcher = dataFetcher;
    this.experiments = experiments;
    this.biLogger = biLogger;

    this.renderHeaderComponent();
  }

  private handleResize = () => {
    const newClosestBreakpoint = this.findClosestBreakpoint();
    if (newClosestBreakpoint !== this.currentClosestBreakpoint) {
      this.renderHeaderComponent();
    }

    this.currentClosestBreakpoint = newClosestBreakpoint;
  };

  private _extractRedirectToLinks = () => {
    const location = window.location;
    return getAllLanguages().reduce<Record<string, string>>((acc, lang) => {
      const alternateLink = this._extractAlternateLink(lang);
      const alternateHref = alternateLink && alternateLink.getAttribute('href');
      const protocol = location && location.protocol ? location.protocol : 'https:';
      const domain = `${lang === 'en' ? 'www' : lang}.wix.com`;
      const path = location ? `${location.pathname || ''}${location.search || ''}` : '';
      acc[lang] = alternateHref || `${protocol}//${domain}${path}`;
      return acc;
    }, {} as Record<SupportedLanguage, string>);
  };

  private _extractAlternateLink(language: string) {
    if (typeof document === 'undefined') {
      return null;
    }
    return (
      document.querySelector(`link[rel="alternate"][hreflang="${language}"]`) ||
      document.querySelector(`link[rel="alternate"][hreflang="x-default"]`)
    );
  }
}
