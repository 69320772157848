import s from './MenuItemWrapper.scss';
import { NavigationItem } from '../../data/types';

export interface MenuItemWrapperProps {
  className?: string;
  item?: NavigationItem;
  dataHook?: string;
}

export const MenuItemWrapper =
  ({ className, item, dataHook }: MenuItemWrapperProps) =>
  (children: string) => {
    return `
    <li
      class="${s.menuItem} ${className || ''} ${item?.mobileOnly ? s.mobileOnly : ''}"
      ${dataHook ? `data-hook='${dataHook}'` : ''}
    >
      ${children}
    </li>
  `;
  };
