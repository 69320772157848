import { isSupportedLanguage, languagesInLanguagePicker, SupportedLanguage } from '../data/languages';
import { StickyBehaviour } from '@wix/header-footer-provider';

export type HeaderFooterParams = Partial<ConfigData>;

interface ConfigData {
  /** @deprecated use `stickyBehaviour` instead */
  sticky?: boolean;
  headerWrapper: HTMLElement | null;
  languageRedirectUrl: LanguageRedirectUrl | undefined;
  hideMainLinksOnDesktop: boolean;
  language: string;
  postLogin?: string;
  postSignUp?: string;
  postSignOut?: string;
  languagePickerList: string[];
  isWixSite: boolean;
  mainContentSelector?: string;
  headless?: boolean;
  isSSR?: boolean;
  exposeApi?: boolean;
  stickyBehaviour?: StickyBehaviour;
  showTooltipOnLogo?: boolean;
}

export class Config {
  private data: ConfigData = {
    headerWrapper: null,
    hideMainLinksOnDesktop: false,
    languageRedirectUrl: undefined,
    language: 'en',
    languagePickerList: languagesInLanguagePicker,
    isWixSite: false,
    isSSR: false,
    headless: false,
    exposeApi: false,
  };

  constructor(params: HeaderFooterParams = {}) {
    Object.assign(this.data, params);
    if (!isSupportedLanguage(params.language)) {
      console.warn(`Wix Header 2021 doesn't support language "${params.language}"!!!`);
      this.data.language = 'en';
    }
  }

  public getStickyBehaviour = (): StickyBehaviour => {
    if (this.data.stickyBehaviour) {
      return this.data.stickyBehaviour;
    }

    if (this.data.sticky !== undefined) {
      return this.data.sticky ? 'sticky' : 'mobile-only';
    }

    return 'sticky';
  };

  public getLanguage = () => this.data.language as SupportedLanguage;

  public getPostLogin = () => this.data.postLogin;

  public getPostSignUp = () => this.data.postSignUp;

  public getPostSignOut = () => this.data.postSignOut;

  public getHeaderWrapper = () => this.data.headerWrapper;

  public getVersion = () => window.WIX_HF_VERSION;

  public shouldDesktopLinkBeHidden = () => this.data.hideMainLinksOnDesktop;

  public getLanguagePickerList = () => this.data.languagePickerList;

  public isWixSite = () => this.data.isWixSite;

  public getMainContentSelector = () => this.data.mainContentSelector;

  public isHeadless = () => this.data.headless;

  public shouldApiBeExposed = () => this.data.exposeApi;

  public isSSR = () => this.data.isSSR;

  public showTooltipOnLogo = () => this.data.showTooltipOnLogo;
}

export type LanguageRedirectUrl = (param: { language: string }) => string;
