type SigningButtonProps = {
  text: string;
  href: string;
  className: string;
  dataHook?: string;
};
export const SigningButton = ({ className, text, href, dataHook }: SigningButtonProps) => `
    <a
      class="${className}"
      href="${href}"
      data-hook="${dataHook}"
    >
      ${text}
    </a>
`;
