import { Messages } from './translate';
import { Config } from './Config';

enum ENV {
  Local = 'local',
  Production = 'prod',
}

const API = {
  userProfile: {
    [ENV.Production]: 'https://manage.wix.com/_api/wix-html-login-webapp/user/getUserDetails',
    [ENV.Local]: '/getUserDetails',
  },
  profileImage: {
    [ENV.Production]: 'https://manage.wix.com/_api/account-info-server/profileimage',
    [ENV.Local]: '/profileimage',
  },
  staticBase: {
    [ENV.Production]: '//static.parastorage.com/services/header-footer-wix-statics',
    [ENV.Local]: 'http://localhost:3202',
  },
};

export interface UserProfileData {
  userName: string;
}

export class DataFetcher {
  private readonly config: Config;
  private readonly wixHnfVersion: string | undefined;

  constructor(config: Config) {
    this.config = config;
    this.wixHnfVersion = window.WIX_HF_VERSION;
  }

  fetchTranslations() {
    return fetch(`${this.getStaticBaseUrl()}/assets/locales/messages_${this.config.getLanguage()}.json`).then(
      response => response.json(),
    ) as Promise<Messages>;
  }

  async fetchUserProfileData(): Promise<UserProfileData | null> {
    const apiUrl = API.userProfile[this.getEnv()];
    return fetch(apiUrl, { credentials: 'include' })
      .then(res => res.json())
      .then(responseBody => responseBody.payload);
  }

  async fetchUserProfileImage() {
    const defaultProfileImage = `${this.getStaticBaseUrl()}/assets/default-profile-image.svg`;
    try {
      const { profileImage } = (await fetch(API.profileImage[this.getEnv()], { credentials: 'include' }).then(res =>
        res.json(),
      )) as {
        profileImage: string;
      };
      return profileImage === '' ? defaultProfileImage : profileImage;
    } catch (e) {
      return defaultProfileImage;
    }
  }

  private getStaticBaseUrl() {
    if (this.getEnv() === ENV.Production && this.wixHnfVersion) {
      return `${API.staticBase[ENV.Production]}/${this.wixHnfVersion}`;
    } else {
      return API.staticBase[ENV.Local];
    }
  }

  private getEnv(): ENV {
    return window.WIX_HF_DEBUG ? ENV.Local : ENV.Production;
  }
}
