import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';

type Params = {
  listenerTarget?: HTMLElement;
  link: HTMLAnchorElement;
  name?: string;
  biLogger: BiLogger;
};

export const initMenuLinkListeners = ({ biLogger, link, name, listenerTarget = link }: Params) => {
  const handler = () => {
    void biLogger.clickButton({ button_name: name, to: link.href });
  };

  listenerTarget.addEventListener('click', handler);
  listenerTarget.addEventListener('tap', handler);

  fireBiEventsOnHover({
    element: listenerTarget,
    name,
    logger: biLogger,
    href: link.href,
  });
};
