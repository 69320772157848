import { MultiLangHeaderStructure, MultiLangNavigationItem, SEPARATOR } from './types';
import { DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN, DESKTOP_MORE_DROPDOWN, MOBILE } from './screen-widths';

const moreDropdownEntries: MultiLangNavigationItem[] = [
  {
    text: 'menu_studio',
    href: {
      en: 'https://www.wix.com/studio',
    },
    name: 'wix_studio',
    target: '_blank',
    collapsibleIntoMoreDropdown: true,
    lang: ['en'],
  },
  {
    text: 'menu_studio',
    href: {
      pt: 'https://pt.wix.com/studio',
      es: 'https://es.wix.com/studio',
      fr: 'https://fr.wix.com/studio',
      ja: 'https://ja.wix.com/studio',
      de: 'https://de.wix.com/studio',
      cs: 'https://www.wix.com/studio',
      da: 'https://www.wix.com/studio',
      hi: 'https://www.wix.com/studio',
      id: 'https://www.wix.com/studio',
      it: 'https://www.wix.com/studio',
      ko: 'https://www.wix.com/studio',
      nl: 'https://www.wix.com/studio',
      no: 'https://www.wix.com/studio',
      pl: 'https://www.wix.com/studio',
      ru: 'https://www.wix.com/studio',
      sv: 'https://www.wix.com/studio',
      th: 'https://www.wix.com/studio',
      tr: 'https://www.wix.com/studio',
      uk: 'https://www.wix.com/studio',
      vi: 'https://www.wix.com/studio',
      zh: 'https://www.wix.com/studio',
    },
    name: 'wix_studio',
    collapsibleIntoMoreDropdown: true,
    lang: [
      'pt',
      'es',
      'fr',
      'ja',
      'de',
      'cs',
      'da',
      'hi',
      'id',
      'it',
      'ko',
      'nl',
      'no',
      'pl',
      'ru',
      'sv',
      'th',
      'tr',
      'uk',
      'vi',
      'zh',
    ],
  },
  {
    text: 'sub_menu_wix_for_enterprise_v2',
    href: {
      en: 'https://www.wix.com/studio/enterprise',
    },
    name: 'wix_for_enterprise',
    lang: ['en'],
    target: '_blank',
    collapsibleIntoMoreDropdown: true,
  },
  {
    text: 'sub_menu_wix_for_enterprise_v2',
    href: {
      de: 'https://de.wix.com/studio/enterprise',
      es: 'https://es.wix.com/studio/enterprise',
      fr: 'https://fr.wix.com/studio/enterprise',
      pt: 'https://pt.wix.com/studio/enterprise',
      ja: 'https://ja.wix.com/studio/enterprise',
    },
    name: 'wix_for_enterprise',
    lang: ['pt', 'es', 'fr', 'ja', 'de'],
    collapsibleIntoMoreDropdown: true,
  },
];

const collapseIntoMoreDropdownEntries = moreDropdownEntries.map(entry => ({
  ...entry,
  screenWidths: [MOBILE, DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN],
}));

export const headerStructure: MultiLangHeaderStructure = {
  nav: [
    {
      text: 'menu_creation',
      name: 'creation',
      subs: [
        {
          dataHook: 'website-design',
          text: 'sub_sub_menu_website_design',
          description: 'description_website_design_v2',
          href: {
            cs: 'https://cs.wix.com/website/design',
            da: 'https://da.wix.com/website/design',
            de: 'https://de.wix.com/website/design',
            en: 'https://www.wix.com/website/design',
            es: 'https://es.wix.com/diseno/web',
            fr: 'https://fr.wix.com/website/design',
            hi: 'https://www.wix.com/website/design',
            id: 'https://www.wix.com/website/design',
            it: 'https://it.wix.com/website/design',
            ja: 'https://ja.wix.com/website/design',
            ko: 'https://ko.wix.com/website/design',
            nl: 'https://nl.wix.com/website/design',
            no: 'https://no.wix.com/website/design',
            pl: 'https://pl.wix.com/website/design',
            pt: 'https://pt.wix.com/website/design',
            ru: 'https://ru.wix.com/website/design',
            sv: 'https://sv.wix.com/website/design',
            th: 'https://th.wix.com/website/design',
            tr: 'https://tr.wix.com/website/design',
            uk: 'https://uk.wix.com/website/design',
            vi: 'https://www.wix.com/website/design',
            zh: 'https://zh.wix.com/website/design',
          },
          name: 'website_design',
        },
        {
          text: 'sub_sub_menu_website_templates',
          description: 'description_website_templates_v2',
          name: 'website_templates',
          href: {
            cs: 'https://cs.wix.com/website/templates',
            da: 'https://da.wix.com/website/templates',
            de: 'https://de.wix.com/website/templates',
            en: 'https://www.wix.com/website/templates',
            es: 'https://es.wix.com/website/templates',
            fr: 'https://fr.wix.com/website/templates',
            hi: 'https://hi.wix.com/website/templates',
            id: 'https://id.wix.com/website/templates',
            it: 'https://it.wix.com/website/templates',
            ja: 'https://ja.wix.com/website/templates',
            ko: 'https://ko.wix.com/website/templates',
            nl: 'https://nl.wix.com/website/templates',
            no: 'https://no.wix.com/website/templates',
            pl: 'https://pl.wix.com/website/templates',
            pt: 'https://pt.wix.com/website/templates',
            ru: 'https://ru.wix.com/website/templates',
            sv: 'https://sv.wix.com/website/templates',
            th: 'https://th.wix.com/website/templates',
            tr: 'https://tr.wix.com/website/templates',
            uk: 'https://uk.wix.com/website/templates',
            vi: 'https://vi.wix.com/website/templates',
            zh: 'https://zh.wix.com/website/templates',
          },
        },
        {
          text: 'sub_sub_menu_ai_website_builder',
          description: 'description_ai_website_builder',
          name: 'ai_website_builder',
          href: {
            en: 'https://www.wix.com/ai-website-builder',
            fr: 'https://fr.wix.com/ai-website-builder',
            de: 'https://de.wix.com/ai-website-builder',
            ja: 'https://ja.wix.com/ai-website-builder',
            es: 'https://es.wix.com/ai-website-builder',
            pt: 'https://pt.wix.com/ai-website-builder',
            it: 'https://it.wix.com/ai-website-builder',
            ko: 'https://ko.wix.com/ai-website-builder',
            tr: 'https://tr.wix.com/ai-website-builder',
          },
          lang: ['en', 'fr', 'de', 'ja', 'es', 'pt', 'it', 'ko', 'tr'],
        },
        {
          text: 'sub_sub_menu_advanced_web_development',
          description: 'description_advanced_web_development',
          href: {
            cs: 'https://www.wix.com/velo',
            da: 'https://www.wix.com/velo',
            de: 'https://de.wix.com/velo',
            en: 'https://www.wix.com/velo',
            es: 'https://es.wix.com/velo',
            fr: 'https://fr.wix.com/velo',
            hi: 'https://www.wix.com/velo',
            id: 'https://www.wix.com/velo',
            it: 'https://www.wix.com/velo',
            ja: 'https://ja.wix.com/velo',
            ko: 'https://www.wix.com/velo',
            nl: 'https://www.wix.com/velo',
            no: 'https://www.wix.com/velo',
            pl: 'https://www.wix.com/velo',
            pt: 'https://pt.wix.com/velo',
            ru: 'https://www.wix.com/velo',
            sv: 'https://www.wix.com/velo',
            th: 'https://www.wix.com/velo',
            tr: 'https://www.wix.com/velo',
            uk: 'https://www.wix.com/velo',
            vi: 'https://www.wix.com/velo',
            zh: 'https://www.wix.com/velo',
          },
          name: 'advanced_web_development',
          target: '_blank',
        },
        {
          text: 'menu_mobile_app',
          name: 'mobile_app',
          description: 'description_mobile_app_v2',
          href: {
            cs: 'https://cs.wix.com/mobile/wix-app',
            da: 'https://da.wix.com/mobile/wix-app',
            de: 'https://de.wix.com/mobile/wix-app',
            en: 'https://www.wix.com/mobile/wix-app',
            es: 'https://es.wix.com/mobile/wix-app',
            fr: 'https://fr.wix.com/mobile/wix-app',
            hi: 'https://www.wix.com/mobile/wix-app',
            id: 'https://www.wix.com/mobile/wix-app',
            it: 'https://it.wix.com/mobile/wix-app',
            ja: 'https://ja.wix.com/mobile/wix-app',
            ko: 'https://ko.wix.com/mobile/wix-app',
            nl: 'https://nl.wix.com/mobile/wix-app',
            no: 'https://no.wix.com/mobile/wix-app',
            pl: 'https://pl.wix.com/mobile/wix-app',
            pt: 'https://pt.wix.com/mobile/wix-app',
            ru: 'https://ru.wix.com/mobile/wix-app',
            sv: 'https://sv.wix.com/mobile/wix-app',
            th: 'https://th.wix.com/mobile/wix-app',
            tr: 'https://tr.wix.com/mobile/wix-app',
            uk: 'https://uk.wix.com/mobile/wix-app',
            vi: 'https://www.wix.com/mobile/wix-app',
            zh: 'https://zh.wix.com/mobile/wix-app',
          },
        },
        {
          text: 'sub_menu_hire_a_partner_v2',
          name: 'hire_a_partner',
          description: 'description_hire_a_partner',
          href: {
            cs: 'https://cs.wix.com/marketplace',
            da: 'https://da.wix.com/marketplace',
            de: 'https://de.wix.com/marketplace',
            en: 'https://www.wix.com/marketplace',
            es: 'https://es.wix.com/marketplace',
            fr: 'https://fr.wix.com/marketplace',
            hi: 'https://www.wix.com/marketplace',
            id: 'https://www.wix.com/marketplace',
            it: 'https://it.wix.com/marketplace',
            ja: 'https://ja.wix.com/marketplace',
            ko: 'https://ko.wix.com/marketplace',
            nl: 'https://nl.wix.com/marketplace',
            no: 'https://no.wix.com/marketplace',
            pl: 'https://pl.wix.com/marketplace',
            pt: 'https://pt.wix.com/marketplace',
            ru: 'https://ru.wix.com/marketplace',
            sv: 'https://sv.wix.com/marketplace',
            th: 'https://th.wix.com/marketplace',
            tr: 'https://tr.wix.com/marketplace',
            uk: 'https://uk.wix.com/marketplace',
            vi: 'https://www.wix.com/marketplace',
            zh: 'https://zh.wix.com/marketplace',
          },
          target: '_blank',
        },
      ],
      sideBlockInfo: [
        {
          text: 'side_info_custom_domain_name',
          name: 'custom_domain_name',
          href: {
            cs: 'https://www.wix.com/domain/names',
            da: 'https://www.wix.com/domain/names',
            de: 'https://de.wix.com/domain/check',
            en: 'https://www.wix.com/domain/names',
            es: 'https://es.wix.com/dominio/gratis',
            fr: 'https://fr.wix.com/nom-de-domaine/gratuit',
            hi: 'https://www.wix.com/domain/names',
            id: 'https://www.wix.com/domain/names',
            it: 'https://it.wix.com/domain/names',
            ja: 'https://ja.wix.com/domain/names',
            ko: 'https://ko.wix.com/domain/names',
            nl: 'https://nl.wix.com/domain/names',
            no: 'https://no.wix.com/domain/names',
            pl: 'https://pl.wix.com/domain/names',
            pt: 'https://pt.wix.com/dominio/gratis',
            ru: 'https://ru.wix.com/domain/names',
            sv: 'https://sv.wix.com/domain/names',
            th: 'https://th.wix.com/domain/names',
            tr: 'https://tr.wix.com/domain/names',
            uk: 'https://www.wix.com/domain/names',
            vi: 'https://www.wix.com/domain/names',
            zh: 'https://zh.wix.com/domain/names',
          },
        },
        {
          text: 'side_info_secure_web_hosting',
          name: 'secure_web_hosting',
          href: {
            cs: 'https://cs.wix.com/free/web-hosting',
            da: 'https://da.wix.com/webhosting',
            de: 'https://de.wix.com/hosting',
            en: 'https://www.wix.com/free/web-hosting',
            es: 'https://es.wix.com/hosting/gratis',
            fr: 'https://fr.wix.com/hebergement/gratuit',
            hi: 'https://www.wix.com/free/web-hosting',
            id: 'https://www.wix.com/free/web-hosting',
            it: 'https://it.wix.com/free/web-hosting',
            ja: 'https://ja.wix.com/free/web-hosting',
            ko: 'https://ko.wix.com/free/web-hosting',
            nl: 'https://nl.wix.com/webhosting',
            no: 'https://no.wix.com/gratis/webhotell',
            pl: 'https://pl.wix.com/darmowy/hosting',
            pt: 'https://pt.wix.com/hospedagem-de-site/gratis',
            ru: 'https://ru.wix.com/besplatnyj-hosting',
            sv: 'https://sv.wix.com/webbhotell',
            th: 'https://th.wix.com/free/web-hosting',
            tr: 'https://tr.wix.com/ucretsiz/hosting',
            uk: 'https://uk.wix.com/free/web-hosting',
            vi: 'https://www.wix.com/free/web-hosting',
            zh: 'https://zh.wix.com/free/web-hosting',
          },
        },
        {
          text: 'side_info_logo_maker',
          name: 'logo_maker',
          href: {
            cs: 'https://cs.wix.com/logo/maker',
            da: 'https://da.wix.com/logo/maker',
            de: 'https://de.wix.com/logo/erstellen',
            en: 'https://www.wix.com/logo/maker',
            es: 'https://es.wix.com/logo/maker',
            fr: 'https://fr.wix.com/logo/creer-logo',
            hi: 'https://hi.wix.com/logo/maker',
            id: 'https://www.wix.com/logo/maker',
            it: 'https://it.wix.com/logo/creare-logo',
            ja: 'https://ja.wix.com/logo/maker',
            ko: 'https://ko.wix.com/logo/maker',
            nl: 'https://nl.wix.com/logo/maker',
            no: 'https://no.wix.com/logo/maker',
            pl: 'https://pl.wix.com/logo/maker',
            pt: 'https://pt.wix.com/logo/criar-logo',
            ru: 'https://ru.wix.com/logo/maker',
            sv: 'https://sv.wix.com/logo/maker',
            th: 'https://www.wix.com/logo/maker',
            tr: 'https://tr.wix.com/logo/maker',
            uk: 'https://www.wix.com/logo/maker',
            vi: 'https://www.wix.com/logo/maker',
            zh: 'https://www.wix.com/logo/maker',
          },
          target: '_blank',
        },
        {
          text: 'side_info_seo_toolset',
          name: 'seo',
          href: {
            cs: 'https://cs.wix.com/seo',
            da: 'https://da.wix.com/seo',
            de: 'https://de.wix.com/seo',
            en: 'https://www.wix.com/seo',
            es: 'https://es.wix.com/seo',
            fr: 'https://fr.wix.com/seo',
            hi: 'https://www.wix.com/seo',
            id: 'https://www.wix.com/seo',
            it: 'https://it.wix.com/seo',
            ja: 'https://ja.wix.com/seo',
            ko: 'https://ko.wix.com/seo',
            nl: 'https://nl.wix.com/seo',
            no: 'https://no.wix.com/seo',
            pl: 'https://pl.wix.com/seo',
            pt: 'https://pt.wix.com/seo',
            ru: 'https://ru.wix.com/seo',
            sv: 'https://sv.wix.com/seo',
            th: 'https://th.wix.com/seo',
            tr: 'https://tr.wix.com/seo',
            uk: 'https://uk.wix.com/seo',
            vi: 'https://www.wix.com/seo',
            zh: 'https://zh.wix.com/seo',
          },
        },
      ],
      sideBlockInfoTitle: 'sub_menu_website_essentials',
      sideBlockInfoButton: {
        text: 'side_info_button_partner',
        name: 'partner',
        description: 'side_info_button_partner_description',
        href: {
          cs: 'https://www.wix.com/studio/partner-program',
          da: 'https://www.wix.com/studio/partner-program',
          de: 'https://de.wix.com/studio/partner-program',
          en: 'https://www.wix.com/studio/partner-program',
          es: 'https://es.wix.com/studio/partner-program',
          fr: 'https://fr.wix.com/studio/partner-program',
          hi: 'https://www.wix.com/studio/partner-program',
          id: 'https://www.wix.com/studio/partner-program',
          it: 'https://www.wix.com/studio/partner-program',
          ja: 'https://ja.wix.com/studio/partner-program',
          ko: 'https://www.wix.com/studio/partner-program',
          nl: 'https://www.wix.com/studio/partner-program',
          no: 'https://www.wix.com/studio/partner-program',
          pl: 'https://www.wix.com/studio/partner-program',
          pt: 'https://pt.wix.com/studio/partner-program',
          ru: 'https://www.wix.com/studio/partner-program',
          sv: 'https://www.wix.com/studio/partner-program',
          th: 'https://www.wix.com/studio/partner-program',
          tr: 'https://www.wix.com/studio/partner-program',
          uk: 'https://www.wix.com/studio/partner-program',
          vi: 'https://www.wix.com/studio/partner-program',
          zh: 'https://www.wix.com/studio/partner-program',
        },
        target: '_blank',
      },
    },
    {
      text: 'menu_business',
      name: 'business',
      subs: [
        {
          text: 'sub_sub_menu_online_store',
          description: 'description_online_store',
          name: 'online_store',
          target: '_blank',
          href: {
            cs: 'https://cs.wix.com/ecommerce/website',
            da: 'https://da.wix.com/ecommerce/website',
            de: 'https://de.wix.com/ecommerce/onlineshop-erstellen',
            en: 'https://www.wix.com/ecommerce/website',
            es: 'https://es.wix.com/ecommerce/tienda-online',
            fr: 'https://fr.wix.com/ecommerce/site-vente-en-ligne',
            hi: 'https://www.wix.com/ecommerce/website',
            id: 'https://www.wix.com/ecommerce/website',
            it: 'https://it.wix.com/ecommerce/negozio-online',
            ja: 'https://ja.wix.com/ecommerce/netshop',
            ko: 'https://ko.wix.com/ecommerce/website',
            nl: 'https://nl.wix.com/ecommerce/webshop-maken',
            no: 'https://no.wix.com/ecommerce/starte-nettbutikk',
            pl: 'https://pl.wix.com/ecommerce/sklep-internetowy',
            pt: 'https://pt.wix.com/ecommerce/loja-virtual',
            ru: 'https://ru.wix.com/ecommerce/internet-magazin',
            sv: 'https://sv.wix.com/ecommerce/starta-webshop',
            th: 'https://th.wix.com/ecommerce/website',
            tr: 'https://tr.wix.com/ecommerce/eticaret-sitesi',
            uk: 'https://uk.wix.com/ecommerce/website',
            vi: 'https://www.wix.com/ecommerce/website',
            zh: 'https://zh.wix.com/ecommerce/website',
          },
        },
        {
          text: 'sub_menu_online_services',
          description: 'description_online_services',
          name: 'online_services',
          href: {
            cs: 'https://cs.wix.com/bookings/scheduling-software',
            da: 'https://da.wix.com/bookings/scheduling-software',
            de: 'https://de.wix.com/online-buchungssystem',
            en: 'https://www.wix.com/scheduling-software',
            es: 'https://es.wix.com/agendador-de-citas',
            fr: 'https://fr.wix.com/reservation-en-ligne',
            hi: 'https://www.wix.com/scheduling-software',
            id: 'https://www.wix.com/scheduling-software',
            it: 'https://it.wix.com/bookings/scheduling-software',
            ja: 'https://ja.wix.com/reserve',
            ko: 'https://ko.wix.com/bookings/scheduling-software',
            nl: 'https://nl.wix.com/bookings/scheduling-software',
            no: 'https://no.wix.com/bookings/scheduling-software',
            pl: 'https://pl.wix.com/bookings/scheduling-software',
            pt: 'https://pt.wix.com/sistema-de-agendamento',
            ru: 'https://ru.wix.com/bookings/scheduling-software',
            sv: 'https://sv.wix.com/bookings/scheduling-software',
            th: 'https://th.wix.com/bookings/scheduling-software',
            tr: 'https://tr.wix.com/bookings/scheduling-software',
            uk: 'https://uk.wix.com/bookings/scheduling-software',
            vi: 'https://www.wix.com/scheduling-software',
            zh: 'https://zh.wix.com/bookings/scheduling-software',
          },
        },
        {
          text: 'sub_sub_menu_restaurants',
          description: 'description_restaurants',
          name: 'restaurants',
          href: {
            cs: 'https://cs.wix.com/restaurant/website',
            da: 'https://da.wix.com/restaurant/website',
            de: 'https://de.wix.com/restaurant/website',
            en: 'https://www.wix.com/restaurant/website',
            es: 'https://es.wix.com/restaurant/website',
            fr: 'https://fr.wix.com/restaurant/website',
            hi: 'https://www.wix.com/restaurant/website',
            id: 'https://www.wix.com/restaurant/website',
            it: 'https://it.wix.com/restaurant/website',
            ja: 'https://ja.wix.com/restaurant/website',
            ko: 'https://ko.wix.com/restaurant/website',
            nl: 'https://nl.wix.com/restaurant/website',
            no: 'https://no.wix.com/restaurant/website',
            pl: 'https://pl.wix.com/restaurant/website',
            pt: 'https://pt.wix.com/restaurant/website',
            ru: 'https://ru.wix.com/restaurant/website',
            sv: 'https://sv.wix.com/restaurant/website',
            th: 'https://th.wix.com/restaurant/website',
            tr: 'https://tr.wix.com/restaurant/website',
            uk: 'https://uk.wix.com/restaurant/website',
            vi: 'https://www.wix.com/restaurant/website',
            zh: 'https://zh.wix.com/restaurant/website',
          },
        },
        {
          text: 'sub_menu_business_blog',
          description: 'description_business_blog',
          name: 'blog',
          href: {
            cs: 'https://cs.wix.com/start/blog',
            da: 'https://da.wix.com/start/lav-en-blog',
            de: 'https://de.wix.com/start/blog-erstellen',
            en: 'https://www.wix.com/start/blog',
            es: 'https://es.wix.com/start/crear-blog',
            fr: 'https://fr.wix.com/start/creer-blog',
            hi: 'https://hi.wix.com/start/blog',
            id: 'https://www.wix.com/start/blog',
            it: 'https://it.wix.com/start/creare-un-blog',
            ja: 'https://ja.wix.com/start/blog-kaisetsu',
            ko: 'https://ko.wix.com/start/beullogeu-mandeulgi',
            nl: 'https://nl.wix.com/start/blog-maken',
            no: 'https://no.wix.com/start/lag-blogg',
            pl: 'https://pl.wix.com/start/jak-zalozyc-bloga',
            pt: 'https://pt.wix.com/start/criar-blog',
            ru: 'https://ru.wix.com/start/sozdat-blog',
            sv: 'https://sv.wix.com/start/starta-blogg',
            th: 'https://th.wix.com/start/blog',
            tr: 'https://tr.wix.com/start/blog-nasil-acilir',
            uk: 'https://www.wix.com/start/blog',
            vi: 'https://www.wix.com/start/blog',
            zh: 'https://zh.wix.com/start/blog',
          },
        },
        {
          text: 'sub_sub_menu_fitness',
          description: 'description_fitness',
          name: 'fitness',
          href: {
            en: 'https://www.wix.com/fitness-solution',
            ja: 'https://ja.wix.com/fitness/website',
          },
          lang: ['en', 'ja'],
        },
      ],
      sideBlockInfoTitle: 'side_info_tools_features',
      sideBlockInfo: [
        {
          text: 'side_info_online_payments',
          name: 'online_payments',
          href: {
            cs: 'https://cs.wix.com/features/invoice-software',
            da: 'https://da.wix.com/features/invoice-software',
            de: 'https://de.wix.com/payments',
            en: 'https://www.wix.com/payments',
            es: 'https://es.wix.com/payments',
            fr: 'https://fr.wix.com/payments',
            hi: 'https://www.wix.com/payments',
            id: 'https://www.wix.com/payments',
            it: 'https://it.wix.com/payments',
            ja: 'https://ja.wix.com/features/invoice-software',
            ko: 'https://ko.wix.com/payments/payment-gateways',
            nl: 'https://nl.wix.com/payments/payment-gateways',
            no: 'https://no.wix.com/features/invoice-software',
            pl: 'https://pl.wix.com/payments/payment-gateways',
            pt: 'https://pt.wix.com/pagamentos',
            ru: 'https://ru.wix.com/payments/payment-gateways',
            sv: 'https://sv.wix.com/features/invoice-software',
            th: 'https://th.wix.com/features/invoice-software',
            tr: 'https://tr.wix.com/payments/payment-gateways',
            uk: 'https://uk.wix.com/payments/payment-gateways',
            vi: 'https://www.wix.com/payments',
            zh: 'https://zh.wix.com/features/invoice-software',
          },
          target: '_blank',
        },
        {
          text: 'side_info_multichannel_sales',
          name: 'multichannel_sales',
          href: {
            de: 'https://de.wix.com/ecommerce/sell',
            en: 'https://www.wix.com/ecommerce/sell',
            es: 'https://es.wix.com/ecommerce/sell',
            fr: 'https://fr.wix.com/ecommerce/sell',
            it: 'https://it.wix.com/ecommerce/sell',
            ko: 'https://ko.wix.com/ecommerce/sell',
            nl: 'https://nl.wix.com/ecommerce/sell',
            pl: 'https://pl.wix.com/ecommerce/sell',
            pt: 'https://pt.wix.com/ecommerce/sell',
            ru: 'https://ru.wix.com/ecommerce/sell',
            tr: 'https://tr.wix.com/ecommerce/sell',
          },
          lang: ['en', 'es', 'fr', 'pt', 'de', 'ru', 'ko', 'nl', 'tr', 'it', 'pl'],
          target: '_blank',
        },
        {
          text: 'side_info_point_of_sale',
          name: 'point_of_sale',
          href: 'https://www.wix.com/pos',
          lang: ['en'],
        },
        {
          text: 'sub_sub_menu_business_email',
          name: 'business_email',
          href: {
            cs: 'https://cs.wix.com/business/email-address',
            da: 'https://da.wix.com/business/email-address',
            hi: 'https://hi.wix.com/business/email-address',
            id: 'https://www.wix.com/business/email-address',
            it: 'https://it.wix.com/email/professionale',
            ja: 'https://ja.wix.com/business/email-address',
            no: 'https://no.wix.com/business/email-address',
            sv: 'https://sv.wix.com/business/email-address',
            th: 'https://th.wix.com/business/email-address',
            tr: 'https://tr.wix.com/kurumsal/e-posta',
            uk: 'https://uk.wix.com/business/email-address',
            vi: 'https://www.wix.com/business/email-address',
            zh: 'https://zh.wix.com/business/email-address',
          },
          lang: ['cs', 'da', 'hi', 'id', 'it', 'ja', 'no', 'sv', 'th', 'tr', 'uk', 'vi', 'zh'],
        },
        {
          text: 'sub_sub_menu_branded_app_builder',
          name: 'branded_app_builder',
          href: {
            cs: 'https://cs.wix.com/app-builder',
            da: 'https://da.wix.com/app-builder',
            de: 'https://de.wix.com/app-builder',
            es: 'https://es.wix.com/app-builder',
            fr: 'https://fr.wix.com/app-builder',
            hi: 'https://www.wix.com/app-builder',
            id: 'https://www.wix.com/app-builder',
            ja: 'https://ja.wix.com/app-development',
            ko: 'https://ko.wix.com/app-builder',
            nl: 'https://nl.wix.com/app-builder',
            no: 'https://no.wix.com/app-builder',
            pl: 'https://pl.wix.com/app-builder',
            pt: 'https://pt.wix.com/app-builder',
            ru: 'https://ru.wix.com/app-builder',
            sv: 'https://sv.wix.com/app-builder',
            th: 'https://th.wix.com/app-builder',
            uk: 'https://uk.wix.com/app-builder',
            vi: 'https://www.wix.com/app-builder',
            zh: 'https://zh.wix.com/app-builder',
          },
          lang: [
            'cs',
            'da',
            'de',
            'es',
            'fr',
            'hi',
            'id',
            'ja',
            'ko',
            'nl',
            'no',
            'pl',
            'pt',
            'ru',
            'sv',
            'th',
            'uk',
            'vi',
            'zh',
          ],
        },
        {
          text: 'side_info_analytics_reports',
          name: 'analytics_reports',
          href: {
            cs: 'https://cs.wix.com/manage/analytics',
            da: 'https://da.wix.com/manage/analytics',
            de: 'https://de.wix.com/manage/analytics',
            en: 'https://www.wix.com/manage/analytics',
            es: 'https://es.wix.com/manage/analisis-web',
            fr: 'https://fr.wix.com/manage/analytics',
            hi: 'https://www.wix.com/manage/analytics',
            id: 'https://www.wix.com/manage/analytics',
            it: 'https://it.wix.com/manage/analytics',
            ja: 'https://ja.wix.com/manage/analytics',
            ko: 'https://ko.wix.com/manage/analytics',
            nl: 'https://nl.wix.com/manage/analytics',
            no: 'https://no.wix.com/manage/analytics',
            pl: 'https://pl.wix.com/manage/analytics',
            pt: 'https://pt.wix.com/manage/web-analytics',
            ru: 'https://ru.wix.com/manage/analytics',
            sv: 'https://sv.wix.com/manage/analytics',
            th: 'https://th.wix.com/manage/analytics',
            tr: 'https://tr.wix.com/manage/analytics',
            uk: 'https://uk.wix.com/manage/analytics',
            vi: 'https://www.wix.com/manage/analytics',
            zh: 'https://zh.wix.com/manage/analytics',
          },
        },
        {
          text: 'side_info_business_tools',
          name: 'business_tools',
          href: {
            cs: 'https://cs.wix.com/tools',
            da: 'https://da.wix.com/tools',
            de: 'https://de.wix.com/tools',
            en: 'https://www.wix.com/tools',
            es: 'https://es.wix.com/tools',
            fr: 'https://fr.wix.com/tools',
            hi: 'https://www.wix.com/tools',
            id: 'https://www.wix.com/tools',
            it: 'https://it.wix.com/tools',
            ja: 'https://ja.wix.com/tools',
            ko: 'https://ko.wix.com/tools',
            nl: 'https://nl.wix.com/tools',
            no: 'https://no.wix.com/tools',
            pl: 'https://pl.wix.com/tools',
            pt: 'https://pt.wix.com/tools',
            ru: 'https://ru.wix.com/tools',
            sv: 'https://sv.wix.com/tools',
            th: 'https://th.wix.com/tools',
            tr: 'https://tr.wix.com/tools',
            uk: 'https://uk.wix.com/tools',
            vi: 'https://www.wix.com/tools',
            zh: 'https://zh.wix.com/tools',
          },
          lang: ['en'],
        },
        {
          text: 'side_info_event_creation',
          name: 'event_creation',
          href: {
            cs: 'https://cs.wix.com/event/website',
            da: 'https://da.wix.com/event/website',
            de: 'https://de.wix.com/event/website',
            en: 'https://www.wix.com/event/website',
            es: 'https://es.wix.com/event/website',
            fr: 'https://fr.wix.com/event/website',
            hi: 'https://www.wix.com/event/website',
            id: 'https://www.wix.com/event/website',
            it: 'https://it.wix.com/event/website',
            ja: 'https://ja.wix.com/event/website',
            ko: 'https://ko.wix.com/event/website',
            nl: 'https://nl.wix.com/event/website',
            no: 'https://no.wix.com/event/website',
            pl: 'https://pl.wix.com/event/website',
            pt: 'https://pt.wix.com/event/website',
            ru: 'https://ru.wix.com/event/website',
            sv: 'https://sv.wix.com/event/website',
            th: 'https://th.wix.com/event/website',
            tr: 'https://tr.wix.com/event/website',
            uk: 'https://uk.wix.com/event/website',
            vi: 'https://www.wix.com/event/website',
            zh: 'https://zh.wix.com/event/website',
          },
        },
      ],
    },
    {
      text: 'menu_resources',
      name: 'resources',
      subs: [
        {
          text: 'sub_menu_tools_features',
          description: 'description_tools_features',
          name: 'tools_features',
          href: {
            cs: 'https://cs.wix.com/features/main',
            da: 'https://da.wix.com/features/main',
            de: 'https://de.wix.com/features/main',
            en: 'https://www.wix.com/features/main',
            es: 'https://es.wix.com/features/main',
            fr: 'https://fr.wix.com/features/main',
            hi: 'https://hi.wix.com/features/main',
            id: 'https://www.wix.com/features/main',
            it: 'https://it.wix.com/features/main',
            ja: 'https://ja.wix.com/features/main',
            ko: 'https://ko.wix.com/features/main',
            nl: 'https://nl.wix.com/features/main',
            no: 'https://no.wix.com/features/main',
            pl: 'https://pl.wix.com/features/main',
            pt: 'https://pt.wix.com/features/main',
            ru: 'https://ru.wix.com/features/main',
            sv: 'https://sv.wix.com/features/main',
            th: 'https://th.wix.com/features/main',
            tr: 'https://tr.wix.com/features/main',
            uk: 'https://uk.wix.com/features/main',
            vi: 'https://www.wix.com/features/main',
            zh: 'https://zh.wix.com/features/main',
          },
        },
        {
          text: 'sub_menu_web_design_inspiration',
          description: 'description_inspirations_v2',
          name: 'web_design_inspiration',
          href: {
            cs: 'https://cs.wix.com/explore/websites',
            da: 'https://da.wix.com/explore/websites',
            de: 'https://de.wix.com/explore/websites',
            en: 'https://www.wix.com/explore/websites',
            es: 'https://es.wix.com/explore/websites',
            fr: 'https://fr.wix.com/explore/websites',
            hi: 'https://hi.wix.com/explore/websites',
            id: 'https://www.wix.com/explore/websites',
            it: 'https://it.wix.com/explore/websites',
            ja: 'https://ja.wix.com/explore/websites',
            ko: 'https://ko.wix.com/explore/websites',
            nl: 'https://nl.wix.com/explore/websites',
            no: 'https://no.wix.com/explore/websites',
            pl: 'https://pl.wix.com/explore/websites',
            pt: 'https://pt.wix.com/explore/websites',
            ru: 'https://ru.wix.com/explore/websites',
            sv: 'https://sv.wix.com/explore/websites',
            th: 'https://th.wix.com/explore/websites',
            tr: 'https://tr.wix.com/explore/websites',
            uk: 'https://www.wix.com/explore/websites',
            vi: 'https://www.wix.com/explore/websites',
            zh: 'https://zh.wix.com/explore/websites',
          },
        },
        {
          text: 'sub_menu_app_integrations',
          description: 'description_resources_app_integrations_v2',
          name: 'resources_app_integrations',
          target: '_blank',
          href: {
            cs: 'https://cs.wix.com/app-market',
            da: 'https://da.wix.com/app-market',
            de: 'https://de.wix.com/app-market',
            es: 'https://es.wix.com/app-market',
            fr: 'https://fr.wix.com/app-market',
            hi: 'https://hi.wix.com/app-market',
            id: 'https://id.wix.com/app-market',
            it: 'https://it.wix.com/app-market',
            ja: 'https://ja.wix.com/app-market',
            ko: 'https://ko.wix.com/app-market',
            nl: 'https://nl.wix.com/app-market',
            no: 'https://no.wix.com/app-market',
            pl: 'https://pl.wix.com/app-market',
            pt: 'https://pt.wix.com/app-market',
            ru: 'https://ru.wix.com/app-market',
            sv: 'https://sv.wix.com/app-market',
            th: 'https://th.wix.com/app-market',
            tr: 'https://tr.wix.com/app-market',
            uk: 'https://uk.wix.com/app-market',
            vi: 'https://vi.wix.com/app-market',
            zh: 'https://zh.wix.com/app-market',
          },
          lang: [
            'cs',
            'da',
            'de',
            'es',
            'fr',
            'hi',
            'id',
            'it',
            'ja',
            'ko',
            'nl',
            'no',
            'pl',
            'pt',
            'ru',
            'sv',
            'th',
            'tr',
            'uk',
            'vi',
            'zh',
          ],
        },
        {
          text: 'sub_menu_wix_learn',
          description: 'description_resources_wix_learn',
          name: 'wix-learn',
          target: '_blank',
          href: {
            en: 'https://www.wix.com/learn',
          },
          lang: ['en'],
        },
        {
          text: 'sub_menu_wix_blog',
          description: 'description_wix_blog_v2',
          name: 'wix_blog',
          target: '_blank',
          href: {
            cs: 'https://www.wix.com/blog',
            da: 'https://www.wix.com/blog',
            de: 'https://de.wix.com/blog',
            en: 'https://www.wix.com/blog',
            es: 'https://es.wix.com/blog',
            fr: 'https://fr.wix.com/blog',
            hi: 'https://www.wix.com/blog',
            id: 'https://www.wix.com/blog',
            it: 'https://it.wix.com/blog',
            ja: 'https://ja.wix.com/blog',
            ko: 'https://ko.wix.com/blog',
            nl: 'https://nl.wix.com/blog',
            no: 'https://www.wix.com/blog',
            pl: 'https://pl.wix.com/blog',
            pt: 'https://pt.wix.com/blog',
            ru: 'https://ru.wix.com/blog',
            sv: 'https://www.wix.com/blog',
            th: 'https://www.wix.com/blog',
            tr: 'https://tr.wix.com/blog',
            uk: 'https://www.wix.com/blog',
            vi: 'https://www.wix.com/blog',
            zh: 'https://www.wix.com/blog',
          },
        },
      ],
    },
    {
      text: 'menu_pricing',
      name: 'pricing',
      href: {
        cs: 'https://cs.wix.com/premium-purchase-plan/dynamo',
        da: 'https://da.wix.com/premium-purchase-plan/dynamo',
        de: 'https://de.wix.com/premium-purchase-plan/dynamo',
        en: 'https://www.wix.com/plans',
        es: 'https://es.wix.com/premium-purchase-plan/dynamo',
        fr: 'https://fr.wix.com/premium-purchase-plan/dynamo',
        hi: 'https://hi.wix.com/premium-purchase-plan/dynamo',
        id: 'https://id.wix.com/premium-purchase-plan/dynamo',
        it: 'https://it.wix.com/premium-purchase-plan/dynamo',
        ja: 'https://ja.wix.com/premium-purchase-plan/dynamo',
        ko: 'https://ko.wix.com/premium-purchase-plan/dynamo',
        nl: 'https://nl.wix.com/premium-purchase-plan/dynamo',
        no: 'https://no.wix.com/premium-purchase-plan/dynamo',
        pl: 'https://pl.wix.com/premium-purchase-plan/dynamo',
        pt: 'https://pt.wix.com/premium-purchase-plan/dynamo',
        ru: 'https://ru.wix.com/premium-purchase-plan/dynamo',
        sv: 'https://sv.wix.com/premium-purchase-plan/dynamo',
        th: 'https://th.wix.com/premium-purchase-plan/dynamo',
        tr: 'https://tr.wix.com/premium-purchase-plan/dynamo',
        uk: 'https://uk.wix.com/premium-purchase-plan/dynamo',
        vi: 'https://vi.wix.com/premium-purchase-plan/dynamo',
        zh: 'https://zh.wix.com/premium-purchase-plan/dynamo',
      },
      collapsibleIntoMoreDropdown: true,
    },
    {
      text: 'menu_support',
      name: 'support',
      href: {
        cs: 'https://support.wix.com/en/',
        da: 'https://support.wix.com/da/',
        de: 'https://support.wix.com/de/',
        en: 'https://support.wix.com/en/',
        es: 'https://support.wix.com/es/',
        fr: 'https://support.wix.com/fr/',
        hi: 'https://support.wix.com/en/',
        id: 'https://support.wix.com/en/',
        it: 'https://support.wix.com/it/',
        ja: 'https://support.wix.com/ja/',
        ko: 'https://support.wix.com/ko/',
        nl: 'https://support.wix.com/nl/',
        no: 'https://support.wix.com/no/',
        pl: 'https://support.wix.com/pl/',
        pt: 'https://support.wix.com/pt/',
        ru: 'https://support.wix.com/ru/',
        sv: 'https://support.wix.com/sv/',
        th: 'https://support.wix.com/en/',
        tr: 'https://support.wix.com/tr/',
        uk: 'https://support.wix.com/en/',
        vi: 'https://support.wix.com/en/',
        zh: 'https://support.wix.com/en/',
      },
      collapsibleIntoMoreDropdown: true,
    },
    {
      text: 'menu_mobile_app',
      name: 'mobile_app',
      href: {
        cs: 'https://cs.wix.com/mobile/wix-app',
        da: 'https://da.wix.com/mobile/wix-app',
        de: 'https://de.wix.com/mobile/wix-app',
        en: 'https://www.wix.com/mobile/wix-app',
        es: 'https://es.wix.com/mobile/wix-app',
        fr: 'https://fr.wix.com/mobile/wix-app',
        hi: 'https://www.wix.com/mobile/wix-app',
        id: 'https://www.wix.com/mobile/wix-app',
        it: 'https://it.wix.com/mobile/wix-app',
        ja: 'https://ja.wix.com/mobile/wix-app',
        ko: 'https://ko.wix.com/mobile/wix-app',
        nl: 'https://nl.wix.com/mobile/wix-app',
        no: 'https://no.wix.com/mobile/wix-app',
        pl: 'https://pl.wix.com/mobile/wix-app',
        pt: 'https://pt.wix.com/mobile/wix-app',
        ru: 'https://ru.wix.com/mobile/wix-app',
        sv: 'https://sv.wix.com/mobile/wix-app',
        th: 'https://th.wix.com/mobile/wix-app',
        tr: 'https://tr.wix.com/mobile/wix-app',
        uk: 'https://uk.wix.com/mobile/wix-app',
        vi: 'https://www.wix.com/mobile/wix-app',
        zh: 'https://zh.wix.com/mobile/wix-app',
      },
      mobileOnly: true,
    },
    SEPARATOR,
    {
      isMoreDropdown: true,
      screenWidths: [DESKTOP_MORE_DROPDOWN],
      omitInSsr: true,
      text: 'menu_more_dropdown',
      name: 'more_dropdown',
      subs: [...moreDropdownEntries],
    },
    ...collapseIntoMoreDropdownEntries,
  ],
  mySites: {
    text: 'menu_my_sites',
    name: 'my_sites',
    href: 'https://www.wix.com/my-account',
  },
  userMenu: [
    {
      text: 'menu_account_settings',
      name: 'account_settings',
      href: 'https://www.wix.com/account/account-settings',
    },
    {
      text: 'menu_billing_and_payments',
      name: 'billing',
      href: 'https://www.wix.com/wix/api/PaymentConsole',
    },
    {
      text: 'menu_vouchers',
      name: 'vouchers',
      href: 'https://www.wix.com/account/vouchers',
    },
    {
      text: '',
      name: 'language',
    },
    {
      text: 'menu_sign_out',
      name: 'sign_out',
      href: 'https://www.wix.com/signout',
    },
  ],
};
