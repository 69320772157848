import { MenuItemWrapper } from '../MenuItemWrapper/MenuItemWrapper';
import { MenuButton } from '../MenuButton/MenuButton';
import s from './MenuItem.scss';
import { NavigationItem } from '../../data/types';
import { Translator } from '../../modules/translate';
import { SubMenu } from '../SubMenu/SubMenu';
import { DataHook } from '../../modules/dataHooks';
import { MoreDropdown } from '../MoreDropdown/MoreDropdown';

export interface MenuItemProps {
  item: NavigationItem;
  t: Translator;
}

export const MenuItem = ({ item, t }: MenuItemProps) => {
  const { subs = [] } = item;

  const id = `main-menu-item-${item.name}`;
  const button = MenuButton({ item, t, id });

  let children = button;
  const classNames = [s.menuItemWrapper];

  if (subs.length) {
    if (item.isMoreDropdown) {
      children = `
        ${button}
        ${MoreDropdown({ id, items: subs, t })}
      `;
    } else {
      const isBigDropdown = subs.length > 4;
      if (isBigDropdown) {
        classNames.push(s.twoColumnsWrapper);
      }

      children = `
        ${button}
        ${SubMenu({
          items: subs,
          blockButton: item.sideBlockInfoButton,
          blockList: item.sideBlockInfo,
          title: item.sideBlockInfoTitle,
          t,
          isBigDropdown,
          id,
        })}
      `;
    }
  }

  if (item.collapsibleIntoMoreDropdown) {
    classNames.push(s.hidden);
  }

  return MenuItemWrapper({
    item,
    className: classNames.join(' '),
    dataHook: DataHook.MainMenuItem,
  })(children);
};
