export function trapFocus(
  firstElement: HTMLElement | (() => HTMLElement),
  lastElement: HTMLElement | (() => HTMLElement),
) {
  const handler = (e: KeyboardEvent) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    const firstFocusableElement = typeof firstElement === 'function' ? firstElement() : firstElement;
    const lastFocusableElement = typeof lastElement === 'function' ? lastElement() : lastElement;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  };
  document.addEventListener('keydown', handler);
  return handler;
}
