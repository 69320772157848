import { BaseElementController } from '../../modules/BaseElementController';
import { DropdownController } from '../../domain/Dropdown';
import { BiLogger } from '../../modules/BiLogger';
import { initMenuLinkListeners } from '../shared';
import { SubDropdownController } from './SubDropdown/SubDropdownController';

import s from './MoreDropdown.scss';

interface MoreDropdownControllerParams {
  el: HTMLElement;
  biLogger: BiLogger;
}

export class MoreDropdownController extends BaseElementController implements DropdownController {
  private readonly container: HTMLElement;
  private readonly biLogger: BiLogger;

  constructor({ el, biLogger }: MoreDropdownControllerParams) {
    super();

    this.biLogger = biLogger;
    this.container = el;

    this.initListeners();

    const subDropdownController = new SubDropdownController(biLogger);
    subDropdownController.init();
  }

  initListeners() {
    const links = this.$$<HTMLAnchorElement>(`.${s.link}`, this.container);
    for (const link of links) {
      initMenuLinkListeners({
        biLogger: this.biLogger,
        link,
        name: this.getLinkBiName(link),
      });
    }
  }

  private getLinkBiName(link: HTMLAnchorElement) {
    return link.getAttribute('data-bi-name') ?? undefined;
  }

  open() {
    this.container.classList.add(s.open);
  }

  close() {
    this.container.classList.remove(s.open);
  }
}
