import { MOBILE_MAX_WIDTH } from './breakpoints';

export abstract class BaseElementController {
  protected $ = <T extends Element>(
    query: string,
    context: Element | Document = document.querySelector('#WIX_HEADER')!,
  ) => context.querySelector<T>(query);
  protected $$ = <T extends Element>(
    query: string,
    context: Element | Document = document.querySelector('#WIX_HEADER')!,
  ) => Array.from(context.querySelectorAll<T>(query));
  protected getSelectorByDataHook = (dataHook: string) => `[data-hook=${dataHook}]`;

  protected isDesktop = () => window.innerWidth > MOBILE_MAX_WIDTH;

  protected createElementFromHTML = <T extends Element>(htmlString: string): T => {
    const div: HTMLDivElement = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstElementChild as T;
  };
}
