import { NavigationItem } from '../../data/types';
import { Translator } from '../../modules/translate';
import { DataHook } from '../../modules/dataHooks';

import s from './MoreDropdown.scss';
import { Link, LinkProps } from './Link';
import { SubDropdown } from './SubDropdown/SubDropdown';

type MoreDropdownProps = {
  id: string;
  items: NavigationItem[];
  t: Translator;
};

export const MoreDropdown = ({ id, items, t }: MoreDropdownProps) => {
  return `
    <ul class="${s.list}" data-hook="${DataHook.MoreDropdown}" role="group" aria-labelledby="${id}">
      ${items.map(item => MoreDropdownItem({ item, t })).join('')}
    </ul>
  `;
};

interface MoreDropdownItemProps {
  item: NavigationItem;
  t: Translator;
}

export const MoreDropdownItem = ({ item, t }: MoreDropdownItemProps) => {
  const navigationItemToLinkProps = (item: NavigationItem): LinkProps => ({
    text: t(item.text),
    href: item.href ?? '',
    target: item.target,
    biName: item.name ?? '',
  });

  let children: string;

  if (item.subs) {
    const subDropdownItems = item.subs.map(navigationItemToLinkProps);
    children = SubDropdown({
      label: t(item.text),
      biName: item.name ?? '',
      items: subDropdownItems,
    });
  } else {
    const props = navigationItemToLinkProps(item);
    children = Link({ ...props, className: s.bold });
  }

  return `<li class="${s.item}">${children}</li>`;
};
