import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';
import { Config } from '../../modules/Config';
import { Translator } from '../../modules/translate';
import { BaseElementController } from '../../modules/BaseElementController';
import { SigningButton } from './SigningButton';
import { createSigningPageUrl } from './createSigningPageUrl';
import { SigningType } from './common';

export type SigningButtonControllerParams = {
  biLogger: BiLogger;
  config: Config;
  t: Translator;
};
export type RenderParams = {
  translationKey: string;
  className: string;
  biName: string;
  isMobile?: boolean;
  signingType: SigningType;
  dataHook?: string;
};

export class SigningButtonController extends BaseElementController {
  private readonly biLogger: BiLogger;
  private readonly config: Config;
  private readonly t: Translator;

  constructor({ biLogger, config, t }: SigningButtonControllerParams) {
    super();

    this.biLogger = biLogger;
    this.config = config;
    this.t = t;
  }

  render({ translationKey, className, biName, isMobile, signingType, dataHook }: RenderParams): Element {
    const { t, config } = this;
    const href = createSigningPageUrl({ config, isMobile, signingType });

    const element = this.createElementFromHTML(
      SigningButton({
        href,
        text: t(translationKey),
        className,
        dataHook,
      }),
    );
    this.addListeners(element, biName, href);

    return element;
  }

  private addListeners(element: Element, biName: string, href: string) {
    element.addEventListener('click', e => {
      e.preventDefault();

      this.biLogger.clickButton({ button_name: biName, to: href });
      window?.location.assign(href);
    });
    fireBiEventsOnHover({ element, href, name: biName, logger: this.biLogger });
  }
}
