import { DropdownArrow } from '../../DropdownArrow/DropdownArrow';
import { Link, LinkProps } from '../Link';

import s from './SubDropdown.scss';

type SubDropdownProps = {
  label: string;
  biName: string;
  items: LinkProps[];
};
export const SubDropdown = ({ label, items, biName }: SubDropdownProps) => `
  <div class="${s.container}">
    <button class="${s.button}" data-bi-name="${biName}">
      ${label}
      ${DropdownArrow({ className: s.arrow })}
    </button>
    <ul class="${s.list}">
      ${items.map(item => `<li class="${s.item}">${Link(item)}</li>`).join('')}
    </ul>
  </div>
`;
