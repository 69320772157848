import { BaseElementController } from '../../modules/BaseElementController';
import s from './Overlay.scss';

interface OverlayCallbacks {
  onClick?: (e: MouseEvent) => void;
}

export class OverlayController extends BaseElementController {
  public readonly element: HTMLDivElement;
  constructor() {
    super();
    this.element = this.$<HTMLDivElement>(`.${s.overlay}`)!;
  }

  init({ onClick }: OverlayCallbacks = {}) {
    this.element.addEventListener('click', e => {
      onClick && onClick(e);
    });
  }

  public show() {
    if (!this.element.classList.contains(s.showOverlay)) {
      this.element.classList.add(s.showOverlay);
      document.body.classList.add(s.fixedBody);
    }
  }

  public hide() {
    if (this.element.classList.contains(s.showOverlay)) {
      this.element.classList.remove(s.showOverlay);
      document.body.classList.remove(s.fixedBody);
    }
  }
}
