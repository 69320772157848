interface DropdownArrowProps {
  className?: string;
}

export const DropdownArrow = ({ className }: DropdownArrowProps = {}) => {
  return `<div class="${className}">
  <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="6 6 12 12">
    <path d="M7 9.79a.5.5 0 0 1 .854-.353L12 13.584l4.146-4.147a.5.5 0 1 1 .708.708L12 14.998l-4.854-4.853A.5.5 0 0 1 7 9.79Z"/>
  </svg>
</div>`;
};
