import { BaseElementController } from '../../modules/BaseElementController';
import { MenuItemController } from '../MenuItem/MenuItemController';
import { DataHook } from '../../modules/dataHooks';
import { MenuItem, MenuItemProps } from '../MenuItem/MenuItem';
import { BiLogger } from '../../modules/BiLogger';
import { Config } from '../../modules/Config';
import s from './MainMenu.scss';
import { MenuItemWrapper, MenuItemWrapperProps } from '../MenuItemWrapper/MenuItemWrapper';
import { DropdownAnimation } from '../../domain/Dropdown';
import { Experiments } from '../../experiments';
import { Translator } from '../../modules/translate';
import { SigningSectionType } from '../SigningSection/common';

type ConstructorParams = {
  biLogger: BiLogger;
  config: Config;
  experiments: Experiments;
  t: Translator;
};
type InitParams = {
  onClickMenuItem: Function;
  mobileSigningSectionType?: SigningSectionType;
};

export class MainMenuController extends BaseElementController {
  private openedMenu: MenuItemController | null = null;
  private menuItems: MenuItemController[];
  private readonly elements: {
    wrapper: HTMLDivElement;
    menu: HTMLUListElement;
    separator: HTMLLIElement;
  };
  private readonly biLogger: BiLogger;
  private readonly config: Config;
  private readonly experiments: Experiments;
  private readonly t: Translator;
  private callback: {
    onClickMenuItem?: Function;
  } = {};

  constructor({ biLogger, config, experiments, t }: ConstructorParams) {
    super();

    this.biLogger = biLogger;
    this.config = config;
    this.experiments = experiments;
    this.t = t;

    this.elements = {
      wrapper: this.$<HTMLDivElement>(`.${s.menuWrapper}`)!,
      menu: this.$<HTMLUListElement>(`.${s.menu}`)!,
      separator: this.$<HTMLLIElement>(`.${s.separator}`)!,
    };

    this.elements.separator.classList.remove(s.hidden);

    this.menuItems = this.$$<HTMLLIElement>(this.getSelectorByDataHook(DataHook.MainMenuItem)).map(
      el =>
        new MenuItemController({
          el,
          biLogger: this.biLogger,
          config,
          experiments,
          t,
        }),
    )!;
  }

  public init({ onClickMenuItem, mobileSigningSectionType }: InitParams) {
    if (mobileSigningSectionType === SigningSectionType.TWO_BUTTONS) {
      this.elements.menu.classList.add(s.twoButtonsInSigningSection);
    }
    if (mobileSigningSectionType === SigningSectionType.EMPTY) {
      this.elements.menu.classList.add(s.noSigningSection);
    }

    this.callback.onClickMenuItem = onClickMenuItem;
    this.menuItems.forEach(item => {
      item.initListeners({
        onClickItemWithSubMenu: this.onClickItemWithSubMenu,
      });
    });

    if (this.config.shouldDesktopLinkBeHidden()) {
      this.elements.separator.classList.add(s.mobileOnly);
    }

    window.addEventListener('keyup', this.handleEscapeButtonPressed);
  }

  onClickItemWithSubMenu = (menuItem: MenuItemController) => {
    if (this.openedMenu === null) {
      menuItem.open(DropdownAnimation.Expanding);
      this.biLogger.openTab({ tab: menuItem.getName() });

      this.openedMenu = menuItem;
    } else if (this.openedMenu === menuItem) {
      menuItem.close();
      this.biLogger.closeTab({ tab: menuItem.getName() });

      this.openedMenu = null;
    } else {
      this.openedMenu.close();

      menuItem.open(DropdownAnimation.Fade);
      this.biLogger.openTab({ tab: menuItem.getName() });

      this.openedMenu = menuItem;
    }
    this.callback.onClickMenuItem && this.callback.onClickMenuItem();
  };

  handleEscapeButtonPressed = (e: KeyboardEvent) => {
    const isEscPressed = e.key === 'Escape' || e.key === 'Esc';

    if (isEscPressed && this.openedMenu) {
      this.openedMenu.getButtonToggleElement().focus();
      this.collapseOpenedMenu();
    }
  };

  unshiftElement(el: Element) {
    const { menu } = this.elements;
    menu.insertBefore(el, menu.firstElementChild);
  }

  pushItem(el: Element) {
    this.elements.menu.appendChild(el);
  }

  collapseOpenedMenu() {
    void this.openedMenu?.close();
    this.openedMenu = null;
  }

  createMenuItem(props: MenuItemProps) {
    const menuItem = new MenuItemController({
      el: this.createElementFromHTML<HTMLLIElement>(MenuItem(props)),
      biLogger: this.biLogger,
      config: this.config,
      experiments: this.experiments,
      t: this.t,
    });
    this.menuItems.push(menuItem);
    return menuItem;
  }

  createWrappedMenuItem({ children, ...props }: MenuItemWrapperProps & { children: Element[] }) {
    const el = this.createElementFromHTML<HTMLLIElement>(MenuItemWrapper(props)(''));
    el.append(...children);
    return el;
  }

  isMenuHasScrollBar() {
    const { menu } = this.elements;
    return menu.scrollHeight > menu.clientHeight;
  }

  getMenuElement() {
    return this.elements.menu;
  }

  getMainMenuItem(name: string) {
    return this.menuItems.find(item => item.getName() === name);
  }
}
