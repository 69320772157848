export enum SigningType {
  SignUp = 'SignUp', // the user has no account
  LogIn = 'LogIn', // the user already has an account
  SignIn = 'SignIn', // generic signing, old experience
}

export enum SigningSectionType {
  EMPTY,
  ONE_BUTTON,
  TWO_BUTTONS,
}
