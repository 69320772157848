export enum DataHook {
  MainMenuItem = 'wix-header-main-menu-item', // used in _common.scss as selector
  AccountNameButton = 'wix-header-account-name-button',
  SubMenu = 'wix-header-sub-menu',
  MoreDropdown = 'wix-header-more-dropdown',
  MenuButton = 'wix-header-menu-button',
  SideInfoBlock = 'wix-header-side-info-block',
  SkipButton = 'wix-header-skip-button',
  HamburgerButton = 'wix-header-hamburger-button',
}
