import s from './SideInfoBigButton.scss';
import { NavigationItem } from '../../data/types';
import { Translator } from '../../modules/translate';

interface SideInfoBigButtonProps {
  blockButton: NavigationItem;
  t: Translator;
}

export const SideInfoBigButton = ({ blockButton, t }: SideInfoBigButtonProps) => {
  return `
  <div class="${s.container}">
    <a
    class="${s.link}"
    href="${blockButton.href}"
    data-bi-name="${blockButton.name}"
    ${blockButton.target ? `target="${blockButton.target}"` : ''}
    >
      <span class="${s.title}">${t(blockButton.text)}</span>
      <span class="${s.description}">${t(blockButton.description!)}</span>
      <div class="${s.arrow}">→</div>
    </a>
  </div>`;
};
