import { BaseElementController } from '../../../modules/BaseElementController';

import s from './SubDropdown.scss';
import { BiLogger, fireBiEventsOnHover } from '../../../modules/BiLogger';

export class SubDropdownController extends BaseElementController {
  private isOpen = false;

  constructor(private readonly biLogger: BiLogger) {
    super();
  }

  init() {
    const button = this.$<HTMLButtonElement>(`.${s.button}`);
    const contentContainer = this.$<HTMLDivElement>(`.${s.list}`);

    if (button && contentContainer) {
      const buttonBiName = this.getBiName(button);

      button.addEventListener('click', () => {
        void this.biLogger.clickButton({
          button_name: buttonBiName,
        });

        const action = this.isOpen ? 'remove' : 'add';
        contentContainer.classList[action](s.open);
        button.classList[action](s.active);

        this.isOpen = !this.isOpen;
      });

      fireBiEventsOnHover({
        element: button,
        name: buttonBiName,
        logger: this.biLogger,
      });
    }
  }

  private getBiName(element?: HTMLElement) {
    return element?.getAttribute('data-bi-name') ?? undefined;
  }
}
