import { LanguagesIcon } from '../LanguageIcon/LanguageIcon';
import s from './LanguageButton.scss';
import { MenuItemWrapper } from '../MenuItemWrapper/MenuItemWrapper';
import { DropdownArrow } from '../DropdownArrow/DropdownArrow';
import { Translator } from '../../modules/translate';
import { getLanguageFullName, SupportedLanguage } from '../../data/languages';

interface LanguageButtonProps {
  language: SupportedLanguage;
  t: Translator;
}

export const languageButtonId = 'main-menu-item-languages';

export const LanguageButton = ({ language, t }: LanguageButtonProps) => {
  return MenuItemWrapper({
    className: s.languages,
  })(`
      <div class="${s.wrapper}">
        <button
          class="${s.desktopLink}"
          aria-label="${t('language_picker_open_button')}"
          aria-haspopup="true"
        >
          ${LanguagesIcon(s.iconLanguages)}
        </button>
        <button
          class="${s.mobileLink}"
          id="${languageButtonId}"
          aria-label="${t('language_picker_open_button')}"
          aria-expanded="false"
        >
          ${LanguagesIcon(s.iconLanguages)}
          <span class="${s.iconText}">${getLanguageFullName(language) || ''}</span>
          ${DropdownArrow({ className: s.arrow })}
        </button>
      </div>
  `);
};
