import messages from '../assets/locales/messages_en.json';
import { SupportedLanguage } from './languages';
import { Experiments } from '../experiments';

export type TextKey = keyof typeof messages;

export const SEPARATOR = 'SEPARATOR';

export type Separator = typeof SEPARATOR;

export type UserMenuStructure = NavigationItem[];

// we need new names
// maybe HeaderStructureSchema ---- Builder --> HeaderStructure?
export type MultiLangHeaderStructure = {
  mySites: NavigationItem;
  userMenu: UserMenuStructure;
  nav: (MultiLangNavigationItem | Separator)[];
};
export type HeaderStructure = Omit<MultiLangHeaderStructure, 'nav'> & {
  nav: (NavigationItem | Separator)[];
  languagePickerMenu: LanguageItem[];
};

export type ScreenWidthsRange = [number, number];

export type MultiLangNavigationItem = {
  dataHook?: string;
  text: TextKey;
  description?: TextKey;
  href?: LanguageHrefMap;
  name?: string;
  lang?: SupportedLanguage[];
  target?: '_blank';
  subs?: MultiLangNavigationItem[];
  collapsibleIntoMoreDropdown?: boolean;
  isMoreDropdown?: boolean;
  mobileOnly?: boolean;
  sideBlockInfo?: MultiLangNavigationItem[];
  sideBlockInfoTitle?: TextKey;
  sideBlockInfoButton?: MultiLangNavigationItem;
  screenWidths?: ScreenWidthsRange[];
  experiments?: Partial<Experiments>;
  omitInSsr?: boolean;
};

export type NavigationItem = Omit<
  MultiLangNavigationItem,
  'href' | 'subs' | 'lang' | 'text' | 'sideBlockInfo' | 'sideBlockInfoButton' | 'screenWidths' | 'experiments'
> & {
  href?: string;
  subs?: NavigationItem[];
  text: string;
  sideBlockInfo?: NavigationItem[];
  sideBlockInfoTitle?: TextKey;
  sideBlockInfoButton?: NavigationItem;
};

export type LanguageItem = {
  text: string;
  name: string;
  href?: string;
};

export type LanguageHrefMap = Partial<Record<SupportedLanguage, string>> | string;
