import s from './MenuToggle.scss';
import { DataHook } from '../../modules/dataHooks';
import { Config } from '../../modules/Config';

export interface MenuToggleProps {
  t(key: string): string;
  config: Config;
}

export const MenuToggle = ({ t, config }: MenuToggleProps) => {
  return `
    <button
      class="${s.hamburger} ${config.isSSR() ? s.hideOnMobile : ''}"
      title="${t('open_navigation')}"
      aria-label="${t('open_navigation')}"
      data-toggle="collapse"
      aria-haspopup="true"
      data-expanded="false"
      aria-expanded="false"
      data-hook="${DataHook.HamburgerButton}"
    >
    <span class="${s.hamburgerBox}">
      <span class="${s.hamburgerInner}"></span>
    </span>
    </button>
  `;
};
