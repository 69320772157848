import s from './SubMenu.scss';
import subMenuItemStyles from '../SubMenuItem/SubMenuItem.scss';
import sideInfoBlockStyles from '../SideInfoBlock/SideInfoBlock.scss';
import sideInfoBigButtonStyles from '../SideInfoBigButton/SideInfoBigButton.scss';
import { SubMenu, SubMenuProps } from './SubMenu';
import { BaseElementController } from '../../modules/BaseElementController';
import { DataHook } from '../../modules/dataHooks';
import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';
import { DropdownAnimation, DropdownController } from '../../domain/Dropdown';
import { Experiments } from '../../experiments';

type ConstructorParams = {
  el: HTMLDivElement;
  biLogger: BiLogger;
  tabName?: string;
  experiments?: Experiments;
};
export class SubMenuController extends BaseElementController implements DropdownController {
  private readonly biLogger: BiLogger;
  private readonly tabName?: string;
  private readonly experiments?: Experiments;
  private readonly elements: {
    container: HTMLDivElement;
    links: HTMLAnchorElement[];
    sideInfoBlock?: {
      container: HTMLDivElement;
      bigButton: HTMLAnchorElement | null;
      links: HTMLAnchorElement[];
    };
  };

  constructor({ el, biLogger, tabName, experiments }: ConstructorParams) {
    super();
    this.biLogger = biLogger;
    this.tabName = tabName;
    this.experiments = experiments;

    this.elements = {
      container: el,
      links: this.$$<HTMLAnchorElement>(`.${subMenuItemStyles.link}`, el),
    };

    const sideInfoBlockHtml = this.$<HTMLDivElement>(this.getSelectorByDataHook(DataHook.SideInfoBlock), el);
    if (sideInfoBlockHtml) {
      this.elements.sideInfoBlock = {
        container: sideInfoBlockHtml,
        bigButton: this.$<HTMLAnchorElement>(`.${sideInfoBigButtonStyles.link}`, sideInfoBlockHtml),
        links: this.$$<HTMLAnchorElement>(`.${sideInfoBlockStyles.link}`, sideInfoBlockHtml),
      };
    }

    this.init();
  }

  private init() {
    this.initListeners();
  }

  open(animation: DropdownAnimation) {
    if (!this.isOpened()) {
      const { container, sideInfoBlock } = this.elements;
      if (animation === DropdownAnimation.Expanding) {
        container.classList.add(s.expand);
        sideInfoBlock?.container.classList.add(sideInfoBlockStyles.expand);
      } else {
        container.classList.add(s.fadeIn);
        sideInfoBlock?.container.classList.add(sideInfoBlockStyles.fadeIn);
      }
    }
  }

  close() {
    if (this.isOpened()) {
      const { container, sideInfoBlock } = this.elements;
      container.classList.remove(s.expand, s.fadeIn);
      sideInfoBlock?.container.classList.remove(sideInfoBlockStyles.expand, sideInfoBlockStyles.fadeIn);
    }
  }

  isOpened() {
    const { container } = this.elements;
    return container.classList.contains(s.expand) || container.classList.contains(s.fadeIn);
  }

  initListeners() {
    const links: HTMLAnchorElement[] = [...this.elements.links];
    if (this.elements.sideInfoBlock) {
      links.push(...this.elements.sideInfoBlock.links);
      const { bigButton } = this.elements.sideInfoBlock;
      if (bigButton) {
        links.push(bigButton);
      }
    }

    links.forEach(link => {
      const wrapper = link.parentElement!;
      wrapper.addEventListener('click', this.onClickLink(link));
      wrapper.addEventListener('tap', this.onClickLink(link));

      fireBiEventsOnHover({
        element: wrapper,
        name: this.getLinkName(link),
        logger: this.biLogger,
        href: link.href,
        tab: this.tabName,
      });
    });
  }

  onClickLink = (link: HTMLAnchorElement) => (e: Event) => {
    e.preventDefault();
    const referencedWindow = link.target === '_blank' ? window.open() : window;
    this.biLogger.clickButton({ button_name: this.getLinkName(link), to: link.href, tab: this.tabName }).then(() => {
      referencedWindow && referencedWindow.location.assign(link.href);
    });
  };

  getLinkName = (link: HTMLAnchorElement) => {
    return link.getAttribute('data-bi-name') || undefined;
  };

  public static renderSubMenu(props: SubMenuProps) {
    return SubMenu(props);
  }
}
