import { NavigationItem } from '../../data/types';
import { Translator } from '../../modules/translate';
import s from './SubMenuItem.scss';

interface SubMenuItemProps {
  item: NavigationItem;
  t: Translator;
}
export const SubMenuItem = ({ item, t }: SubMenuItemProps) => {
  return `
  <li
    class="${s.subMenuItem}"
    ${item.dataHook ? `data-hook="${item.dataHook}"` : ''}
  >
    <a
      class="${s.link}"
      ${item.target ? `target="${item.target}"` : ''}
      href="${item.href}"
      data-bi-name="${item.name}"
    >
      <span class="${s.title}">${t(item.text)}</span>
    </a>
    <span class="${s.description}">${t(item.description || '')}</span>
  </li>
  `;
};
