import s from './MoreDropdown.scss';

export type LinkProps = {
  biName: string;
  text: string;
  href: string;
  target?: string;
  className?: string;
};
export const Link = ({ biName, text, href, target, className }: LinkProps) => `
  <a
    class="${s.link} ${className ?? ''}"
    href="${href}"
    ${target ? `target="${target}"` : ''}
    data-bi-name="${biName}"
  >
    ${text}
  </a>
`;
