import { NavigationItem, SEPARATOR, Separator } from '../../data/types';
import { Translator } from '../../modules/translate';
import { Config } from '../../modules/Config';
import { LanguageButton } from '../LanguageButton/LanguageButton';
import { MenuItem } from '../MenuItem/MenuItem';
import { Separator as SeparatorComponent } from '../Separator/Separator';

import s from './MainMenu.scss';

interface NavigationMenuProps {
  t: Translator;
  config: Config;
  navigationList: (NavigationItem | Separator)[];
}

export const MainMenu = ({ t, config, navigationList }: NavigationMenuProps) => {
  return `
    <nav class="${s.menuWrapper} ${s.fromRightSide}" aria-label="${t('nav_aria_label')}">
      <ul class="${s.menu}">
        ${navigationList
          .map(item => {
            if (item === SEPARATOR) {
              return SeparatorComponent({ tag: 'li', className: `${s.separator} ${s.hidden}` });
            }
            return MenuItem({ item, t });
          })
          .join('')}
        ${config.isHeadless() ? '' : LanguageButton({ language: config.getLanguage(), t })}
      </ul>
    </nav>
  `;
};
