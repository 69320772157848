import { SubMenuController } from '../SubMenu/SubMenuController';
import { MenuButtonController } from '../MenuButton/MenuButtonController';
import { BaseElementController } from '../../modules/BaseElementController';
import { DataHook } from '../../modules/dataHooks';
import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';
import s from './MenuItem.scss';
import { Config } from '../../modules/Config';
import { DropdownAnimation, DropdownController } from '../../domain/Dropdown';
import { MoreDropdownController } from '../MoreDropdown/MoreDropdownController';
import { initMenuLinkListeners } from '../shared';
import { Translator } from '../../modules/translate';
import { Experiments } from '../../experiments';

export interface MenuItemListeners {
  onClickItemWithSubMenu?: (item: MenuItemController) => void;
}

interface MenuItemControllerParams {
  el: HTMLLIElement;
  biLogger: BiLogger;
  config: Config;
  t: Translator;
  experiments: Experiments;
}

export class MenuItemController extends BaseElementController {
  private readonly el: HTMLLIElement;
  private readonly biLogger: BiLogger;
  private readonly config: Config;
  private readonly button: MenuButtonController;
  private readonly dropdown?: DropdownController;
  private readonly t: Translator;
  private readonly experiments: Experiments;
  private isOpened = false;

  constructor({ el, biLogger, config, t, experiments }: MenuItemControllerParams) {
    super();

    this.el = el;
    this.biLogger = biLogger;
    this.config = config;
    this.t = t;
    this.experiments = experiments;

    this.button = new MenuButtonController(
      this.$<HTMLDivElement>(this.getSelectorByDataHook(DataHook.MenuButton), el)!,
    );
    this.dropdown = this.findDropdown(el);

    el.classList.remove(s.hidden);

    if (this.config.shouldDesktopLinkBeHidden()) {
      this.hideOnDesktop();
    }
  }

  findDropdown(container: HTMLElement): DropdownController | undefined {
    const subMenuElement = this.$<HTMLDivElement>(this.getSelectorByDataHook(DataHook.SubMenu), container);
    if (subMenuElement) {
      return new SubMenuController({
        el: subMenuElement,
        biLogger: this.biLogger,
        tabName: this.getName(),
        experiments: this.experiments,
      });
    }

    const moreDropdownElement = this.$<HTMLDivElement>(this.getSelectorByDataHook(DataHook.MoreDropdown), container);
    if (moreDropdownElement) {
      return new MoreDropdownController({
        el: moreDropdownElement,
        biLogger: this.biLogger,
      });
    }
  }

  open(animation: DropdownAnimation) {
    this.dropdown?.open(animation);
    this.button.activate();
    this.isOpened = true;
    if (!this.isDesktop()) {
      setTimeout(
        () =>
          this.button.getButtonWrapper().scrollIntoView({
            behavior: 'smooth',
          }),
        75,
      );
    }
  }

  close() {
    this.dropdown?.close();
    this.button.inactivate();
    this.isOpened = false;
  }

  initListeners(listeners: MenuItemListeners) {
    const buttonWrapper = this.button.getButtonWrapper();

    if (this.dropdown) {
      buttonWrapper.addEventListener('click', this.onClickItemWithSubMenu(listeners));
      buttonWrapper.addEventListener('tap', this.onClickItemWithSubMenu(listeners));

      fireBiEventsOnHover({
        element: buttonWrapper,
        name: this.button.getName(),
        logger: this.biLogger,
        href: this.button.getLink(),
      });
    } else {
      initMenuLinkListeners({
        biLogger: this.biLogger,
        listenerTarget: buttonWrapper,
        link: this.button.getButtonElement() as HTMLAnchorElement,
        name: this.button.getName(),
      });
    }
  }

  onClickItemWithSubMenu =
    ({ onClickItemWithSubMenu }: MenuItemListeners) =>
    () => {
      this.biLogger.clickButton({ button_name: this.button.getName() });
      onClickItemWithSubMenu && onClickItemWithSubMenu(this);
    };

  getName = () => this.button.getName();

  getButtonToggleElement() {
    return this.button.getButtonElement();
  }

  private hideOnDesktop() {
    this.el.classList.add(s.mobileOnly);
  }

  getMainElement() {
    return this.el;
  }
}
