import { Translator } from '../../modules/translate';
import { NavigationItem } from '../../data/types';
import s from './MenuButton.scss';
import { DropdownArrow } from '../DropdownArrow/DropdownArrow';
import { DataHook } from '../../modules/dataHooks';

interface MenuButtonProps {
  t: Translator;
  item: NavigationItem;
  id: string;
}

export const MenuButton = ({ item, t, id }: MenuButtonProps) => {
  if (item.href) {
    return `
    <div class="${s.linkWrapper}" data-hook="${DataHook.MenuButton}">
      <a
        class="${s.link}"
        ${item.target ? `target="${item.target}"` : ''}
        href="${item.href}"
        data-bi-name="${item.name}"
      >
        ${t(item.text)}
      </a>
    </div>
    `;
  }

  return `
    <div class="${s.linkWrapper}" data-hook="${DataHook.MenuButton}">
      <button
          id="${id}"
          class="${s.link} ${s.withSubMenu}"
          data-bi-name="${item.name}"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
        >
          ${t(item.text)}
          ${item.subs?.length ? DropdownArrow({ className: s.arrow }) : ''}
        </button>
    </div>
  `;
};
