import s from './Logo.scss';
import { Translator } from '../../modules/translate';

type LogoTooltipProps = {
  isLogin: boolean;
  t: Translator;
};

export const LogoTooltip = ({ t, isLogin }: LogoTooltipProps) => {
  return `<div class="${s.tooltip}">${isLogin ? t('wix_logo_logged_in_tooltip') : t('wix_logo_anon_tooltip')}</div>`;
};
