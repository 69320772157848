import { Config } from '../../modules/Config';
import { SigningType } from './common';

type CreateSigningPageUrlParams = {
  config: Config;
  signingType: SigningType;
  isMobile?: boolean;
};

export const createSigningPageUrl = ({ config, isMobile, signingType }: CreateSigningPageUrlParams) => {
  const defaultPostSignUp = 'https://www.wix.com/new/intro/';
  const defaultPostLogin = 'https://manage.wix.com/account/route';

  const searchParamsInstance = new URLSearchParams({
    view: 'sign-up',
    sendEmail: 'true',
    loginCompName: isMobile ? 'SignUp_Mobile' : 'SignUp_H',
    referralInfo: isMobile ? 'SignUp_Mobile' : 'SignUp_H',
    postSignUp: config.getPostSignUp() ?? defaultPostSignUp,
    postLogin: config.getPostLogin() ?? defaultPostLogin,
    originUrl: location.href,
  });

  const language = config.getLanguage();
  if (language && language !== 'en') {
    searchParamsInstance.set('overrideLocale', language);
  }

  if (signingType === SigningType.SignUp) {
    searchParamsInstance.set('loginDialogContext', 'signup');
  } else if (signingType === SigningType.LogIn) {
    searchParamsInstance.set('loginDialogContext', 'login');
  }

  const urlInstance = new URL('https://users.wix.com/signin');
  urlInstance.search = searchParamsInstance.toString();

  return urlInstance.href;
};
