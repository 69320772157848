import s from './SideInfoBlock.scss';
import { SideInfoBigButton } from '../SideInfoBigButton/SideInfoBigButton';
import { NavigationItem, TextKey } from '../../data/types';
import { Translator } from '../../modules/translate';
import { DataHook } from '../../modules/dataHooks';

interface SideInfoBlockProps {
  blockButton?: NavigationItem;
  blockList: NavigationItem[];
  title: TextKey;
  t: Translator;
}

export const SideInfoBlock = ({ blockButton, blockList, t, title }: SideInfoBlockProps) => {
  return `
    <div class="${s.aside}" data-hook="${DataHook.SideInfoBlock}">
      <div class="${s.background}"></div>
      <div class="${s.listContainer}">
        <p class="${s.title}">${t(title)}</p>
        <ul class="${s.list}">
          ${blockList
            .map(
              item => `
            <li class="${s.listItem}">
              <a
                class="${s.link}"
                href="${item.href}"
                data-bi-name="${item.name}"
                ${item.target ? `target="${item.target}"` : ''}
                >
                  ${t(item.text)}
                </a>
            </li>`,
            )
            .join('')}
        </ul>
      </div>
      ${blockButton ? SideInfoBigButton({ blockButton, t }) : ''}
    </div>
`;
};
