import ExperimentsManager, { ExperimentsProps as ExperimentsManagerOptions } from '@wix/wix-experiments';
import { SupportedLanguage } from './data/languages';
import { Config } from './modules/Config';

export type Experiments = {};

export const experimentFallbacks: Experiments = {};

export const loadExperiments = async (language: SupportedLanguage, config: Config): Promise<Experiments> => {
  if (config.isHeadless()) {
    return experimentFallbacks;
  }

  const experimentsManager = createExperimentManager();

  return experimentsManager.ready().then(() => ({ ...experimentFallbacks, ...experimentsManager.all() }));
};

const conduct = (experimentsManager: ExperimentsManager, spec: keyof Experiments) => {
  experimentsManager.conduct(spec, experimentFallbacks[spec]);
};

const createExperimentManager = (optionsOverrides: ExperimentsManagerOptions = {}): ExperimentsManager => {
  const defaultOptions: ExperimentsManagerOptions =
    window.location.origin === 'https://status.wix.com'
      ? { baseUrl: 'https://manage.wix.com' }
      : { useNewApi: window.WIX_HF_DEBUG };

  return new ExperimentsManager({ ...defaultOptions, ...optionsOverrides });
};
