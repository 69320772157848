import { BaseElementController } from '../../modules/BaseElementController';
import { LanguageItem } from '../../data/types';
import { Translator } from '../../modules/translate';
import { Config } from '../../modules/Config';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { DataHook } from '../../modules/dataHooks';
import s from './LanguageButton.scss';
import languagePickerStyles from '../LanguagePicker/LanguagePicker.scss';
import { SubMenuController } from '../SubMenu/SubMenuController';
import { BiLogger, fireBiEventsOnHover } from '../../modules/BiLogger';
import { trapFocus } from '../../modules/a11y';
import { languageButtonId } from './LanguageButton';
import { DropdownAnimation } from '../../domain/Dropdown';

interface InitLanguageButtonProps {
  onLanguageButtonClick?: Function;
}

interface LanguageControllerParams {
  languageList: LanguageItem[];
  config: Config;
  t: Translator;
  biLogger: BiLogger;
  headerElement: HTMLHeadingElement;
}

interface LanguageControllerProps extends InitLanguageButtonProps {}

export class LanguageController extends BaseElementController {
  private readonly languageList: LanguageItem[];
  private readonly config: Config;
  private readonly t: Translator;
  private subMenu?: SubMenuController;
  private readonly biLogger: BiLogger;
  private readonly biName = 'languages';
  private readonly elements: {
    header: HTMLHeadingElement;
    menuButtonWrapper: HTMLDivElement;
    popup: {
      container: HTMLDivElement;
      list: HTMLDivElement;
      links: HTMLAnchorElement[];
      closeButton: HTMLButtonElement;
    };
    mobileButton: HTMLButtonElement;
    desktopButton: HTMLButtonElement;
    menuItem: HTMLDivElement;
  };
  private callbacks: {
    onClosePopup?: Function;
  } = {};

  constructor({ languageList, config, t, biLogger, headerElement }: LanguageControllerParams) {
    super();
    this.languageList = languageList;
    this.config = config;
    this.t = t;
    this.biLogger = biLogger;

    const popupElement = this.createElementFromHTML<HTMLDivElement>(
      LanguagePicker({ languageList: this.languageList, config: this.config, t: this.t }),
    );
    this.elements = {
      header: headerElement,
      popup: {
        container: popupElement,
        list: this.$<HTMLDivElement>(`.${languagePickerStyles.languages}`, popupElement)!,
        closeButton: this.$<HTMLButtonElement>(`.${languagePickerStyles.closeButton}`, popupElement)!,
        links: this.$$<HTMLAnchorElement>(`.${languagePickerStyles.link}`, popupElement),
      },
      menuItem: this.$<HTMLDivElement>(`.${s.languages}`)!,
      menuButtonWrapper: this.$<HTMLDivElement>(`.${s.wrapper}`)!,
      desktopButton: this.$<HTMLButtonElement>(`.${s.desktopLink}`)!,
      mobileButton: this.$<HTMLButtonElement>(`.${s.mobileLink}`)!,
    };
  }

  public init(props: LanguageControllerProps = {}) {
    if (!this.config.isHeadless()) {
      this.initLanguageButton(props);
      this.renderMobileLanguageList();

      this.initLanguagePopupListeners();
    }
  }

  initLanguageButton({ onLanguageButtonClick }: InitLanguageButtonProps) {
    this.elements.menuButtonWrapper.addEventListener('click', () => {
      if (this.isDesktop()) {
        this.openLanguagePopup(this.onLanguagePopupClose);
        this.biLogger.openTab({ tab: this.biName });
      } else {
        this.toggleLanguageMobileMenu();
      }
      this.biLogger.clickButton({ button_name: this.biName });

      onLanguageButtonClick && onLanguageButtonClick();
    });

    fireBiEventsOnHover({ element: this.elements.menuButtonWrapper, name: this.biName, logger: this.biLogger });
  }

  public openLanguagePopup(onClosePopupCallback?: Function) {
    this.elements.header.appendChild(this.elements.popup.container);
    const { firstFocusableElements } = this.getLanguagePopupFocusableElements();
    firstFocusableElements.focus();
    this.callbacks.onClosePopup = onClosePopupCallback;
  }

  public initLanguagePopupListeners() {
    const {
      popup: { closeButton, list, links, container },
    } = this.elements;
    container.addEventListener('click', () => {
      this.closePopup();
    });

    list.addEventListener('click', e => {
      e.stopPropagation();
    });

    links.forEach(linkEl => {
      const linkBiName = linkEl.getAttribute('data-bi-name') || 'undefined';

      linkEl.addEventListener('click', e => {
        e.preventDefault();
        const referencedWindow = linkEl.target === '_blank' ? window.open() : window;
        this.biLogger.languageChange({ destination_lng: linkBiName }).then(() => {
          referencedWindow && referencedWindow.location.assign(linkEl.href);
        });
      });

      fireBiEventsOnHover({
        element: linkEl,
        name: linkBiName,
        logger: this.biLogger,
        href: linkEl.href,
        tab: this.biName,
      });
    });

    const { firstFocusableElements, lastFocusableElements } = this.getLanguagePopupFocusableElements();
    trapFocus(firstFocusableElements, lastFocusableElements);

    fireBiEventsOnHover({ element: closeButton, name: 'close', logger: this.biLogger, tab: this.biName });
  }

  public closePopup = () => {
    this.biLogger.closeTab({ tab: this.biName });
    this.elements.popup.container.remove();
    this.callbacks.onClosePopup && this.callbacks.onClosePopup();
  };

  private renderMobileLanguageList() {
    this.elements.menuItem.insertAdjacentHTML(
      'beforeend',
      SubMenuController.renderSubMenu({
        items: this.languageList.filter(({ name }) => this.config.getLanguage() !== name),
        t: this.t,
        id: languageButtonId,
      }),
    );

    this.subMenu = new SubMenuController({
      el: this.getSubMenuElement(),
      biLogger: this.biLogger,
      tabName: this.biName,
    });
  }

  private getSubMenuElement() {
    return this.$<HTMLDivElement>(this.getSelectorByDataHook(DataHook.SubMenu), this.elements.menuItem)!;
  }

  private toggleLanguageMobileMenu() {
    if (this.isOpenedLanguageMobileMenu()) {
      this.closeLanguageMobileMenu();
    } else {
      this.openLanguageMobileMenu();
    }
  }

  public openLanguageMobileMenu() {
    const { mobileButton, menuItem } = this.elements;
    this.biLogger.openTab({ tab: this.biName });
    mobileButton.classList.add(s.active);
    this.subMenu?.open(DropdownAnimation.Expanding);
    mobileButton.setAttribute('aria-expanded', 'true');
    setTimeout(
      () =>
        menuItem.scrollIntoView({
          behavior: 'smooth',
        }),
      75,
    );
  }

  public closeLanguageMobileMenu() {
    const { mobileButton } = this.elements;
    this.biLogger.closeTab({ tab: this.biName });
    mobileButton.classList.remove(s.active);
    this.subMenu?.close();
    mobileButton.setAttribute('aria-expanded', 'false');
  }

  public isOpenedLanguageMobileMenu() {
    return this.subMenu?.isOpened();
  }

  private getLanguagePopupFocusableElements() {
    return {
      firstFocusableElements: this.$<HTMLAnchorElement>(
        `.${languagePickerStyles.link}`,
        this.elements.popup.container,
      )!,
      lastFocusableElements: this.elements.popup.closeButton,
    };
  }

  onLanguagePopupClose = () => {
    this.elements.desktopButton.focus();
  };

  isOpened() {
    return this.elements.popup.container.parentElement !== null;
  }
}
