import { Config, HeaderFooterParams } from './modules/Config';
import { DataFetcher } from './modules/DataFetcher';
import { HeaderFooterController } from './modules/HeaderFooterController';
import { Experiments, loadExperiments } from './experiments';
import { BiLogger } from './modules/BiLogger';

export async function initHeaderAndFooter2020(params: HeaderFooterParams) {
  if (typeof window === 'undefined') {
    console.warn('Wix Header 2021: window is not defined');
    return;
  }
  if (!window.WIX_HF_INITIALIZED) {
    window.WIX_HF_INITIALIZED = true;

    const config = new Config(params);
    const language = config.getLanguage();
    const biLogger = new BiLogger({ version: config.getVersion(), language });

    biLogger.startLoading();
    const experiments = await loadExperiments(language, config);

    initCurrentHeader(config, experiments, biLogger, params);
  } else {
    console.warn('Wix Header 2021 has already been initialized');
  }
}

window.initHeaderAndFooter2020 = initHeaderAndFooter2020;

const initCurrentHeader = async (
  config: Config,
  experiments: Experiments,
  biLogger: BiLogger,
  params: HeaderFooterParams,
) => {
  window.WIX_HF_START_TIME = new Date().getTime();

  const dataFetcher = new DataFetcher(config);
  const messages = await dataFetcher.fetchTranslations();

  const headerFooterController = new HeaderFooterController();
  headerFooterController.renderHeader({ config, messages, dataFetcher, experiments, biLogger });

  if (config.shouldApiBeExposed()) {
    if (window.HeaderAPI) {
      console.error(`HeaderAPI can not be exposed because window.HeaderAPI is already defined!`);
    } else {
      window.HeaderAPI = {
        updateLinks: updateLinksParams => {
          const newConfig = new Config({ ...params, ...updateLinksParams });
          headerFooterController.renderHeader({ config: newConfig, messages, dataFetcher, experiments, biLogger });
        },
      };
    }
  }

  biLogger.loaded();
};
