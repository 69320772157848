import s from './LanguagePicker.scss';
import { CloseIcon } from '../CloseIcon/CloseIcon';
import { Config } from '../../modules/Config';
import { LanguageItem } from '../../data/types';
import { Translator } from '../../modules/translate';

interface LanguagePopupProps {
  config: Config;
  languageList: LanguageItem[];
  t: Translator;
}
export const LanguagePicker = ({ config, languageList, t }: LanguagePopupProps) => {
  const dataBiRow = 'languages';
  return `
    <div class="${s.container}">
      <div class="${s.container2}">
        <ul class="${s.languages}">
          ${languageList
            .map((language, index) => {
              return `
              <li class="${s.language} ${index >= 14 ? s.lastColumn : ''}">
                <a
                  class="${s.link} ${language.name === config.getLanguage() ? s.selected : ''}"
                  href="${language.href}"
                  data-bi-name="${language.name}"
                  data-bi-row="${dataBiRow}"
                >
                  ${language.text}
                </a>
              </li>
            `;
            })
            .join('')}
        </ul>
        <button
          class="${s.closeButton}"
          aria-label="${t('language_picker_close_button')}"
          data-bi-row="${dataBiRow}"
          data-bi-name="close"
        >
          ${CloseIcon()}
        </button>
      </div>
    </div>
  `;
};
